import React from 'react';
import { makeStyles } from "@mui/styles";

import {
  Box,
  IconButton,
  Tooltip
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import AddCircleIcon from '@mui/icons-material/Add';
import {deleteChartColors, setProductOpt} from "store/slices/StockProductSlice";
import ExpandablePanelBox from "components/common/Base/ExpandablePanelBox";
import ColorPicker from "components/common/ColorPicker";
import DeleteIcon from '@mui/icons-material/Delete';
import {useProductContext} from "providers/product/ProductProvider";
import {CustomizeItem, EndNode, StartNode} from "../../../../../components/common/Product/CustomizeItem";

const useStyles = makeStyles(() => ({
  ColorPicker: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    "& svg": {
      fontSize: '18px'
    }
  },
  ParagraphLabel: {
    fontWeight: 'bold'
  }
}));

const bgColors = [
  '#fff','#fce4ec', '#ffcdd2', '#f3e5f5', '#e8eaf6', '#e3f2fd', '#d1c4e9',
  '#c5cae9', '#b3e5fc', '#b2ebf2', '#b2dfdb', '#e0f2f1', '#e6ee9c', '#fff9c4',
  '#eeeeee', '#ffccbc'
]

export const Appearance = () => {
  const dispatch = useDispatch()
  const { chartSettings } = useSelector(state => state.productData)
  const classes = useStyles();
  const { addChartColors } = useProductContext()

  return (
    <ExpandablePanelBox panel={{label: 'Appearance'}} collapsed={true}>
      <Box display='flex' sx={{ flexFlow: 'column', gap: '5px' }}>
        <Box>
          <span className={classes.ParagraphLabel}>Chart Colors</span>
          <Box sx={{ display: 'flex', flexFlow: 'wrap' }}>
            {
              Object.entries(chartSettings?.colors || {})?.map(([key, value]) => (
                <Box className={classes.ColorPicker} key={key}>
                  <ColorPicker
                    color={value}
                    pickerLable={''}
                    handleChange={(color) => {
                      dispatch(setProductOpt({
                        path: `chartSettings.colors.${key}`, value: color.hex
                      }))
                    }}
                  />
                  <IconButton onClick={() => {
                    dispatch(deleteChartColors(key))
                  }}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))
            }
            <Box>
              <Tooltip title="Add Chart Color">
                <IconButton onClick={addChartColors}>
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <CustomizeItem>
          <StartNode>Background Color</StartNode>
          <EndNode>
            <span>{chartSettings?.chart?.backgroundColor || '#fff'}</span>
            <ColorPicker
              colors={bgColors}
              color={chartSettings?.chart?.backgroundColor}
              pickerLable={''}
              handleChange={(color) => {
                dispatch(setProductOpt({
                  path: 'chartSettings.chart.backgroundColor',
                  value: color.hex
                }))
              }}
            />
          </EndNode>
        </CustomizeItem>
        <CustomizeItem>
          <StartNode>Plot Area Color</StartNode>
          <EndNode>
            <span>{chartSettings?.chart?.plotBackgroundColor || '#fff'}</span>
            <ColorPicker
              colors={bgColors}
              color={chartSettings?.chart?.plotBackgroundColor}
              pickerLable={''}
              handleChange={(color) => {
                dispatch(setProductOpt({
                  path: 'chartSettings.chart.plotBackgroundColor',
                  value: color.hex
                }))
              }}
            />
          </EndNode>
        </CustomizeItem>
      </Box>
    </ExpandablePanelBox>
  )
};
