import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setProductOpt} from "store/slices/StockProductSlice";
import BaseAccordion from "../../BaseAccordion";
import {makeStyles} from "@mui/styles";
import {CustomizeItem, EndNode, StartNode} from "components/common/Product/CustomizeItem";
import {Box, FormControlLabel} from "@mui/material";
import {DropdownSelect} from "components/common/Product/DropdownSelect";
import ColorPicker from "components/common/ColorPicker";
import {useProductContext} from "providers/product/ProductProvider";
import {BpCheckbox} from "../../BpCheckbox";

const useStyles = makeStyles(() => ({
  AccordionItem: {
    marginBottom: 10
  },
  Button: {
    width: '35px',
    height: '35px',
    padding: 0,
    minWidth: 0,
    fontSize: '19px'
  }
}));

const bgColors = [
  '#fff','#fce4ec', '#ffcdd2', '#f3e5f5', '#e8eaf6', '#e3f2fd', '#d1c4e9',
  '#c5cae9', '#b3e5fc', '#b2ebf2', '#b2dfdb', '#e0f2f1', '#e6ee9c', '#fff9c4',
  '#eeeeee', '#ffccbc'
]

export const SeriesSpecificType = () => {
  const dispatch = useDispatch()
  const { axes } = useSelector(state => state.productData.chartSettings)
  const { series } = useSelector(state => state.productData)
  const { feedSourceType } = useProductContext()
  const classes = useStyles()

  const [expandedPanel, setExpandedPanel] = useState('')
  const onPanelChange = (val) => {
    if(expandedPanel === val){
      setExpandedPanel('')
    }else{
      setExpandedPanel(val)
    }
  }
  const marketChartTypes = [
    {label: 'Line', id: 'line'},
    {label: 'Area', id: 'area'},
    {label: 'Column', id: 'column'},
    {label: 'Column Pyramid', id: 'columnpyramid'},
  ]
  const nonMarketChartTypes = [
    {label: 'Line', id: 'line'},
    {label: 'Area', id: 'area'},
    {label: 'Column', id: 'column'},
    {label: 'Column Pyramid', id: 'columnpyramid'},
  ]

  return (series.map((serieItem, idx) => (
      <BaseAccordion
        key={idx}
        seriesLabel={serieItem.value}
        isExpanded={expandedPanel === serieItem.value}
        className={classes.AccordionItem}
        onChange={() => onPanelChange(serieItem.value)}
      >
        <Box display="flex" sx={{ flexFlow: 'column', gap: '5px' }}>
          <DropdownSelect
            onChange={(newValue) => {
              dispatch(setProductOpt({
                path: `series.${idx}.chartType`, value: newValue
              }))
            }}
            selectedValue={serieItem.chartType || 'line'}
            placeholder="Series Type"
            options={feedSourceType === 'stock' && serieItem.id === 'default' ? marketChartTypes : nonMarketChartTypes}
          />
          <CustomizeItem>
            <StartNode>Series Color</StartNode>
            <EndNode>
              <span>{serieItem?.color || ''}</span>
              <ColorPicker
                color={serieItem?.color}
                pickerLable={''}
                handleChange={(color) => {
                  dispatch(setProductOpt({
                    path: `series.${idx}.color`, value: color.hex
                  }))
                }}
              />
            </EndNode>
          </CustomizeItem>
          {
            ['line', 'area', undefined].includes(serieItem.chartType) ? (
              <>
                <DropdownSelect
                  onChange={(newValue) => {
                    dispatch(setProductOpt({
                      path: `series.${idx}.dashStyle`, value: newValue
                    }))
                  }}
                  selectedValue={serieItem?.dashStyle || 'Solid' }
                  placeholder="Border Style"
                  options={[
                    {id: 'Solid', label: 'Solid'},
                    {id: 'ShortDash', label: 'Short Dash'},
                    {id: 'ShortDot', label: 'Short Dot'},
                    {id: 'ShortDashDot', label: 'Short DashDot'},
                    {id: 'ShortDashDotDot', label: 'Short DashDotDot'},
                    {id: 'Dot', label: 'Dot'},
                    {id: 'Dash', label: 'Dash'},
                    {id: 'LongDash', label: 'Long Dash'},
                    {id: 'DashDot', label: 'Dash Dot'},
                    {id: 'LongDashDot', label: 'LongDashDot'},
                    {id: 'LongDashDotDot', label: 'Long DashDotDot'},
                  ]}
                />
                <DropdownSelect
                  onChange={(newValue) => {
                    dispatch(setProductOpt({
                      path: `series.${idx}.marker.symbol`, value: newValue
                    }))
                  }}
                  selectedValue={serieItem?.marker?.symbol || 'none' }
                  placeholder="Point Marker"
                  options={[
                    {label: 'None', id: 'none'},
                    {label: 'Circle', id: 'circle'},
                    {label: 'Square', id: 'square'},
                    {label: 'Diamond', id: 'diamond'},
                    {label: 'Triangle', id: 'triangle'},
                  ]}
                />
              </>
            ) : (
              <CustomizeItem>
                <StartNode>Color By Point</StartNode>
                <EndNode>
                  <FormControlLabel
                    value="colorByPoint"
                    control={<BpCheckbox
                      sx={{ padding: '4px 6px' }}
                      onChange={(e) => {
                        dispatch(setProductOpt({
                          path: `series.${idx}.colorByPoint`, value: !!e.target.checked
                        }))
                      }}
                      checked={Boolean(serieItem?.colorByPoint)}
                    />}
                    label={serieItem?.colorByPoint ? 'On' : 'Off'}
                    labelPlacement="start"
                    sx={{ margin: 0, fontSize: '12px' }}
                  />
                </EndNode>
              </CustomizeItem>
            )
          }
        </Box>
      </BaseAccordion>
    ))
  )
};
