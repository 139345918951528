import React, { useCallback, useMemo, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setProductOpt, deleteAnnotation} from "store/slices/StockProductSlice";
import CustomFormInput from "components/common/Base/CustomFormInput";
import BaseAccordion from "../../BaseAccordion";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  Divider,
  Button,
} from "@mui/material";
import {showSnackbarMessage} from "store/slices/MessagesSystemSlice";
import {DropdownSelect} from "components/common/Product/DropdownSelect";
import {CustomizeItem, EndNode, StartNode} from "components/common/Product/CustomizeItem";
import ColorPicker from "components/common/ColorPicker";
import {DeleteOutline} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
  IconButton: {
    // flexGrow: 1,
    minWidth: 0,
    padding: '4px',
    '& svg': {
      width: '20px',
      height: '20px'
    }
  }
}));

const bgColors = [
  '#fff','#fce4ec', '#ffcdd2', '#f3e5f5', '#e8eaf6', '#e3f2fd', '#d1c4e9',
  '#c5cae9', '#b3e5fc', '#b2ebf2', '#b2dfdb', '#e0f2f1', '#e6ee9c', '#fff9c4',
  '#eeeeee', '#ffccbc'
]
export const AnnotationTextLabels = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { chartSettings, annotations } = useSelector(state => state.productData)

  const [selectedTextId, setSelectedTextId] = useState()
  const textOptions = useMemo(() => {
    return annotations.map((item, idx) => {
      return {
        id: item.id,
        label: item.isFresh || item.text === '' ? `Text Label ${idx + 1}` : item.text
      }
    })
  }, [
    annotations
  ])
  const selectedTextOption = useMemo(() => {
    return annotations.filter(item => item.id === selectedTextId)[0] || {}
  }, [
    annotations,
    selectedTextId
  ])
  const onClickAdd = () => {
    dispatch(setProductOpt({
      path: 'activeChartAnchor', value: 'annotation'
    }))
    dispatch(showSnackbarMessage({
      autoHideDuration: null,
      anchorOrigin: { vertical: 'top', horizontal: 'left' },
      severity: "warning",
      message: "Click Any place of Chart Area"
    }))
  }
  const onClickDelete = () => {
    dispatch(deleteAnnotation(selectedTextId))
    setSelectedTextId(null)
  }
  const onChangeTextOption = useCallback((field, newValue) => {
    const idx = annotations.findIndex(item => item.id === selectedTextId)
    if(idx > -1){
      if(annotations[idx].isFresh){
        dispatch(setProductOpt({
          path: `annotations.${idx}.isFresh`, value: false
        }))
      }
      dispatch(setProductOpt({
        path: `annotations.${idx}.${field}`, value: newValue
      }))
    }
  }, [
    annotations,
    selectedTextId
  ])

  return (
    <BaseAccordion
      seriesLabel="Text Labels"
      style={{ marginBottom: '10px' }}
    >
      <Box sx={{ display: 'flex', flexFlow: 'column', gap: '10px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, }}>
          <Box sx={{ flex: 1}}>
            <DropdownSelect
              selectedValue={selectedTextId}
              placeholder={selectedTextId ? undefined : 'Text Labels' }
              onChange={(val) => setSelectedTextId(val)}
              options={textOptions}
            />
          </Box>
          <Button onClick={onClickAdd} className={classes.IconButton} size='small' variant='contained'>
            <AddCircleIcon />
          </Button>
          <Button onClick={onClickDelete} disabled={!selectedTextId} className={classes.IconButton} size='small' variant='contained' color='error'>
            <DeleteOutline />
          </Button>
        </Box>
        {
          selectedTextId && (
            <>
              <Divider />
              <CustomFormInput
                label="Insert Text"
                fieldName="text"
                value={selectedTextOption?.text || ''}
                onChange={(field, value) => onChangeTextOption('text', value)}
              />
              <CustomizeItem>
                <StartNode>Background Color</StartNode>
                <EndNode>
                  <span>{selectedTextOption?.backgroundColor || '#fff'}</span>
                  <ColorPicker
                    colors={bgColors}
                    color={selectedTextOption?.backgroundColor}
                    pickerLable={''}
                    handleChange={(color) => {
                      onChangeTextOption('backgroundColor', color.hex)
                    }}
                  />
                </EndNode>
              </CustomizeItem>
              <CustomizeItem>
                <StartNode>Border Color</StartNode>
                <EndNode>
                  <span>{selectedTextOption?.borderColor || '#fff'}</span>
                  <ColorPicker
                    colors={bgColors}
                    color={selectedTextOption?.borderColor}
                    pickerLable={''}
                    handleChange={(color) => {
                      onChangeTextOption('borderColor', color.hex)
                    }}
                  />
                </EndNode>
              </CustomizeItem>
            </>
          )
        }
      </Box>
    </BaseAccordion>
  )
};
