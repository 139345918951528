import React, {useEffect, useRef} from "react";
// import { useSelector } from 'react-redux'

import 'highcharts/css/stocktools/gui.scss'
import 'highcharts/css/annotations/popup.scss'

import {Box} from "@mui/system";
import moment from 'moment'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Highcharts from 'highcharts/highstock'
import StockData from 'highcharts/modules/data'
import PatternFill from 'highcharts/modules/pattern-fill'
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import DragPane from 'highcharts/modules/drag-panes'
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import AnnotationsAdvanced from 'highcharts/modules/annotations-advanced'
import Annotations from 'highcharts/modules/annotations'
import StockTools from 'highcharts/modules/stock-tools'
import ReactHighcharts from 'highcharts-react-official'

StockData(Highcharts)
DragPane(Highcharts)
HighchartsMore(Highcharts);
StockTools(Highcharts)
PatternFill(Highcharts)
PatternFill(Highcharts)
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);
Annotations(Highcharts)
AnnotationsAdvanced(Highcharts)

function NonMarketHighChart(props) {
  const { options } = props
  const chartRef = useRef(null);
  const boxRef = useRef(null);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (chartRef.current) {
        chartRef.current.chart.reflow();
      }
    });

    if (boxRef.current) {
      observer.observe(boxRef.current);
    }

    return () => {
      if (boxRef.current) {
        observer.unobserve(boxRef.current);
      }
    };
  }, []);

  return (
    <Box
      ref={boxRef}
      style={{ width: '100%', height: '100%' }}
    >
      <LocalizationProvider dateLibInstance={moment} dateAdapter={AdapterDateFns}>
        <ReactHighcharts
          highcharts={Highcharts}
          ref={chartRef}
          constructorType={"chart"}
          options={{
            ...options,
            exporting: {
              enabled: false
            }
          }}
          containerProps={{ style: { height: "100%" } }}
        />
      </LocalizationProvider>
    </Box>
  )
}

export default NonMarketHighChart