import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setProductOpt} from "store/slices/StockProductSlice";
import ExpandablePanelBox from "components/common/Base/ExpandablePanelBox";
import CustomFormInput from "components/common/Base/CustomFormInput";

export const TextFeature = () => {
  const dispatch = useDispatch()
  const { chartSettings } = useSelector(state => state.productData)


  return (
    <ExpandablePanelBox panel={{label: 'Text'}} collapsed={true}>
      <CustomFormInput
        label="Title"
        fieldName="text"
        value={chartSettings?.title?.text || ''}
        onChange={(field, value) => {
          dispatch(setProductOpt({
            path: 'chartSettings.title.text',
            value
          }))
        }}
      />
      <CustomFormInput
        label="Sub Title"
        fieldName="text"
        value={chartSettings?.subtitle?.text || ''}
        onChange={(field, value) => {
          dispatch(setProductOpt({
            path: 'chartSettings.subtitle.text',
            value
          }))
        }}
      />
      <CustomFormInput
        label="Caption"
        type="textarea"
        fieldName="text"
        value={chartSettings?.caption?.text || ''}
        onChange={(field, value) => {
          dispatch(setProductOpt({
            path: 'chartSettings.caption.text',
            value
          }))
        }}
      />
    </ExpandablePanelBox>
  )
};
