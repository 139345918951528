import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from "@mui/styles";

import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Chip, FormControlLabel, IconButton, Switch, Tooltip, Typography } from '@mui/material';

import DataTableColumnFilterPopover from 'components/common/DataTableColumnFilterPopover';

import { Reply, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { getComparator, stableSort } from 'shared/utilities/tableUtils';
import ResultStatusArea from './details/ResultStatusArea';
import useFetchQAReports from 'hooks/FetchQAReports';
import useQaRange from 'hooks/QARange';
import { showSnackbarMessage } from 'store/slices/MessagesSystemSlice';
import { saveQaResultApi, triggerFrontendProductQA } from 'store/slices/FrontendQA';

const cluster = process.env.REACT_APP_QA_CLUSTER

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: "10px",
        background: '#F5F8FF'
    },
    ColorCap: {
        position: 'relative',
        marginRight: '10px',
        paddingLeft: '12px',
        fontSize: '11px',
        color: 'black',
        "&::before": {
            content: '""',
            position: 'absolute',
            background: 'inherit',
            width: '10px',
            height: '10px',
            left: 0,
            top: '3px'
        },
        "&.success": {
            "&::before": {
                backgroundColor: '#00f2c3'
            },
        },
        "&.warning": {
            "&::before": {
                backgroundColor: '#ff8d72'
            },
        },
        "&.danger": {
            "&::before": {
                backgroundColor: '#fd5d93'
            },
        },
    },
    TableCap: {
        padding: '13px',
        alignItems: 'center',
        background: 'white',
        boxShadow: '14px 6px 4px -21px rgba(0,0,0,0.2), 0px -4px 2px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        marginBottom: 8
    },
    Table: {
        marginTop: '11px',
        background: 'white',
        "& .success": {
            background: '#00f2c3'
        },
        "& .warning": {
            background: '#ff8d72'
        },
        "& .danger": {
            background: '#fd5d93'
        },
        "& td": {
            borderRight: 'solid 1px white'
        }
    },
    TableTitle: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        lineHeight: '140%',
        margin: '0px',
        fontSize: '14px',
        letterSpacing: '-0.006em',
        fontWeight: '600',
        color: 'rgb(67, 74, 84)',
        marginRight: '10px',
        textTransform: 'uppercase'
    },
    TableSubTitle: {

    },

    productStaticBarButton: {
        color: '#4e4b4b',
        cursor: 'pointer',
        margin: '0 3px',
        position: 'relative',
        boxShadow: '0 0 1px 1px #e5e5f1',
        transition: 'all ease-in .2s',
        borderRadius: '4px',
        height: '37px',
        width: '37px',
        fontSize: '13px',
        textAlign: 'center',
        '&.selected': {
            boxShadow: '0 0 1px 1px #646467'
        },
        '&:hover': {
            background: '#ececec'
        },
        '&.selectedViewMode': {
            background: '#3c54db',
            color: 'white',
            '&:hover': {
                background: '#5e72e4'
            },
        },
        '& svg': {
            display: 'block',
            margin: '0 auto',
            fontSize: '35px'
        },
        // '&::after': {
        //     content: '""',
        //     position: 'absolute',
        //     height: '1px',
        //     borderTop: 'solid 1px #e2dcdc',
        //     bottom: '-6px',
        //     width: 'calc(100% - 6px)'
        // },
        // '&:last-child': {
        //     '&::after': {
        //         content: 'initial'
        //     }
        // }
    },
}))

const convertToSeconds = (milliseconds, showMiliSeconds = true) => {
    if (!milliseconds) return ''
    const _seconds = Math.round(milliseconds / 1000 * 100) / 100;
    if (!showMiliSeconds) {
        return `${_seconds}s`
    }
    return `${milliseconds}ms`
}

const getPercentageOfTotal = (total, time) => {
    return time > 0 ? `${(time / total * 100).toFixed(2)}%` : 'N/A'
}

function createData(name, status, fat, carbs, protein, score) {
    return { name, status, fat, carbs, protein, score };
}

const thresholdConfigs = {
    MAX_AL: {
        secs: 3707,
        dangerF: 0.5,
        warnF: 1 / 10
    },
    MAX_MDL: {
        secs: 4670,
        dangerF: 0.5,
        warnF: 1 / 20
    },
    MAX_BDL: {
        secs: 297801,
        dangerF: 0.5,
        warnF: 1 / 20
    },
    MAX_TDT: {
        secs: 22700,
        dangerF: 0.5,
        warnF: 1 / 20
    },
    TOTAL: {
        secs: 3000,
        dangerF: 1,
        warnF: 0.7
    },
}

export default function QAResultsTable() {
    const { qaReportId } = useParams()
    const location = useLocation();

    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()

    const [results, setResults] = useState([])
    // const [rangeRows, setRangeRows] = useState({})
    const [totalProgress, setTotalProgress] = useState(0);
    const [currentRange, setCurrentRange] = useState({ //current inprogress range
        id: 0,
        progress: 0,
        score: 0,
        results: [],
        cCountAvg: 0,
        bCountAvg: 0,
        cCountBelowAvg: 0,
        bCountBelowAvg: 0,
        cCountGood: 0,
        bCountGood: 0,
        cCountFail: 0,
        bCountFail: 0,
        cCountPass: 0,
        bCountPass: 0
    })
    const [selectedRangeId, setSelectedRangeId] = useState(0)

    const [loading, setLoading] = useState(false)
    const [qaProcessLoading, setQaProcessLoading] = useState(false)
    const keepQaRef = useRef(true);
    const qaProcessRef = useRef({
        rangeId: null,
        productId: null,
        progress: 0,
        loading: false
    });

    const [blobOpt, setBlobOpt] = useState('cloud-storage')
    const [showMiliSeconds, setShowMiliSeconds] = useState(false)
    const [showTimePercentage, setShowTimePercentage] = useState(false)

    const [fAnchorEl, setFAchorEl] = useState(null)
    const openFilterPop = Boolean(fAnchorEl)
    const [columnSort, setColumnSort] = useState({})
    const [openedColumn, setOpenedColumn] = useState(null)
    const onOpenFilterPop = (openedColumn) => (event) => {
        setFAchorEl(event.currentTarget)
        setOpenedColumn(openedColumn)
    }

    const handleClose = () => {
        setFAchorEl(null)
    }

    const {
        getQaReportData,
        setTotalScore,
        state: {
            qaReportData = {},
            loading: loadingQAReportData,
            totalScore,
        }
    } = useFetchQAReports(dispatch)

    const {
        initQARange,
        getQaResults,
        getQARanges,
        saveQARange,
        setQARanges,
        retryQARange,
        state: {
            loading: loadingQARanges,
            qaRanges
        }
    } = useQaRange(dispatch)

    useEffect(() => {
        getQaReportData(qaReportId)
        getQARanges(qaReportId)
    }, [
        qaReportId
    ])
    // console.log(qaReportData, 'qaReportData')

    const productCountsPerRange = useMemo(() => {
        return Math.ceil(qaReportData.total_products / 100 * qaReportData.range_percentage)
    }, [
        qaReportData.total_products,
        qaReportData.range_percentage
    ]);
    const rangeCount = useMemo(() => {
        if(qaReportData.total_products <= productCountsPerRange) return 1
        if (qaReportData.range_percentage) {
            return Math.ceil(100 / qaReportData.range_percentage)
        }
        return 0
    }, [
        productCountsPerRange,
        qaReportData.range_percentage,
        qaReportData.total_products
    ])

    const rangeRows = useMemo(() => {
        return qaRanges.map((item) => {
            const _startRange = (item.range_index - 1) * qaReportData.range_percentage
            const _endRange = item.range_index * qaReportData.range_percentage
            let rangeProducts = productCountsPerRange * item.range_index > qaReportData.total_products ?
                qaReportData.total_products -  productCountsPerRange * (item.range_index - 1) :
                productCountsPerRange
            const _items = {
                ...item,
                fail_count: blobOpt === 'cloud-storage' ? item['c_fail_count']: item['b_fail_count'],
                pass_count: blobOpt === 'cloud-storage' ? item['c_pass_count']: item['b_pass_count'],
                count_of_good: blobOpt === 'cloud-storage' ? item['c_count_of_good']: item['b_count_of_good'],
                count_of_average: blobOpt === 'cloud-storage' ? item['c_count_of_average']: item['b_count_of_average'],
                count_of_below_average: blobOpt === 'cloud-storage' ? item['c_count_of_below_average']: item['b_count_of_below_average'],
                range_index: item.range_index,
                rangePercentage: `${_startRange}% ~ ${_endRange > 100 ? 100 : _endRange}%`,
                created_at: item.created_at,
                completedProducts: rangeProducts,
                rangeProducts: rangeProducts,
                neededToConfirm: !item.end_product && qaReportData.run_type === 'manual',
            }
            if (item.end_product) {
                _items.completedPercentage = 100
            } else {
                _items.completedPercentage = ((currentRange.progress / productCountsPerRange) * 100).toFixed(2)
                _items.completedProducts = currentRange.progress
            }
            // console.log(item.id, currentRange.id, 'currentRange.id')
            if(item.id == currentRange.id){
                _items.loading = true
                _items.score = currentRange.score
                _items.fail_count = blobOpt === 'cloud-storage' ? currentRange.cCountFail : currentRange.bCountFail
                _items.pass_count = blobOpt === 'cloud-storage' ? currentRange.cCountPass : currentRange.bCountPass
                _items.count_of_good = blobOpt === 'cloud-storage' ? currentRange.cCountGood : currentRange.bCountGood
                _items.count_of_average = blobOpt === 'cloud-storage' ? currentRange.cCountAvg : currentRange.bCountAvg
                _items.count_of_below_average = blobOpt === 'cloud-storage' ? currentRange.cCountBelowAvg : currentRange.bCountBelowAvg
            }
            return _items
        })
    }, [
        qaRanges,
        currentRange,
        qaReportData.total_products,
        productCountsPerRange,
        qaReportData.range_percentage,
        qaReportData.run_type,
        blobOpt
    ])


    const sortedResults = useMemo(() => {
        let _rows = (selectedRangeId == currentRange.id ? (currentRange.results || []) : results)?.filter(item => item.loading_source === blobOpt)

        for (let col in columnSort) {
            _rows = stableSort(_rows, getComparator(columnSort[col], col))
        }
        // console.log(currentRange.id, 'currentRange.id')
        // console.log(blobOpt, 'blobOpt')
        // console.log(_rows, '_rows')
        return _rows
    }, [
        columnSort,
        results,
        selectedRangeId,
        currentRange.id,
        currentRange.results,
        blobOpt
    ])

    const evaluateThreshold = (thresholdType, thresholdVal) => {
        if (!thresholdVal || thresholdVal <= 0) return ''
        let color = 'success'
        const _maxThresHold = thresholdConfigs[thresholdType]
        if (thresholdVal > _maxThresHold.secs * _maxThresHold.dangerF) {
            color = 'danger'
        } else if (thresholdVal >= _maxThresHold.secs * _maxThresHold.warnF) {
            color = 'warning'
        }
        return color;
    }

    const evaluateCategory = (score) => {
        let category = '';
        if (score >= 90) {
            category = "good";
        } else if (score >= 70) {
            category = "average";
        } else {
            category = "below-average";
        }
        return category
    }

    const initCurrentRange = (updates) => {
        const _currentRange = {
            id: 0,
            progress: 0,
            score: 0,
            results: [],
            cCountAvg: 0,
            bCountAvg: 0,
            cCountBelowAvg: 0,
            bCountBelowAvg: 0,
            cCountGood: 0,
            bCountGood: 0,
            cCountFail: 0,
            bCountFail: 0,
            cCountPass: 0,
            bCountPass: 0,
            ...updates
        }
        setCurrentRange({ ..._currentRange })
        return _currentRange
    }

    async function retryWith503Handling(action, maxRetries, errorCallBack) {
        let retries = 0;
        let _errorType = null;

        while (retries < maxRetries) {
            try {
                const response = await dispatch(action);
                // console.log(response, 'response')
                if ((response.payload && response.payload.status == 503) || !response.payload) {
                    retries++;
                    // console.log(retries, 'retries???')
                    _errorType = 503
                } else if (response.payload.status == 500) {
                    retries++;
                    _errorType = 500
                } else if (response.payload.status === "cancelled") {
                    return
                } else {
                    return response;
                }
            } catch (e) {
                _errorType = e.response.status
                // console.log(e, 'error')
                // return
                //     console.log(error, 'error')
                //     if (error.response && error.response.status === 503) {
                //         // Handle 503 error, e.g., log it
                //         console.log('503 error occurred. Retrying...');
                //         retries++;
                //     } else {
                //         // Handle other errors
                // throw e;
                //     }
            }
        }
        if (errorCallBack) {
            return errorCallBack(_errorType)
        }
        return _errorType
    }

    const runQA = async (productUid, qaRangeId) => {
        const r = await retryWith503Handling(
            triggerFrontendProductQA({
                payload: {
                    cluster,
                    reportId: qaRangeId,
                    productUid,
                    maximumSeconds: qaReportData.maximum_sec
                },
            }),
            1, // Maximum number of retries for this product
            async (_errorType) => {
                return { error: _errorType }
            }
        );
        return r
    }
    const qaProcess = async (
        initProduct,
        qaRangeId,
        rangeIndex,
        processType = '',
        pre = {}
    ) => {
        setSelectedRangeId(qaRangeId)
        const _currentRange = pre.currentRange || {}
        qaProcessRef.current = {
            qaRangeId,
            productId: initProduct.uid,
            loading: true,
            progress: _currentRange.progress
        }
        // console.log(initProduct, qaReportId, qaRangeId, rangeIndex, 'in the qaprocess')
        let product = initProduct
        // console.log(_currentRange, 'pre _currentRange')
        setCurrentRange({
            ..._currentRange
        })
        let _totalScore = pre.totalScore || 0
        let nextTable
        let nextRange, modifiedRange
        if (product) {
            const r = await runQA(product.uid, qaRangeId);
            if (r && r.payload) {
                const { nextProduct, results: newResults = [] } = r.payload
                nextTable = nextProduct
                const scoreResults = newResults.filter(item => item.score && item.score)
                const errorResults = newResults.filter(item => ['503', '500', '404'].includes(item.category))
                let newScore = 0
                if (scoreResults.length > 0) {
                    const sum = scoreResults.reduce((acc, obj) => acc + obj.score, 0);
                    newScore = Number(sum / scoreResults.length).toFixed(2)
                    if (_currentRange.score > 0) {
                        _currentRange.score = (parseFloat(newScore) + parseFloat(_currentRange.score)) / 2;
                    }else{
                        _currentRange.score = newScore
                    }
                    if (_totalScore) {
                        _totalScore = (parseFloat(_totalScore) + parseFloat(newScore)) / 2
                    }else{
                        _totalScore = newScore
                    }
                }
                // console.log(newScore, 'newScore')
                // console.log(_totalScore, '_totalScore')
                _currentRange.results = [...newResults, ...(_currentRange.results || [])]
                _currentRange.bCountPass = _currentRange.bCountPass * 1 + scoreResults.filter(item => item.loading_source === 'big-query').length
                _currentRange.cCountPass = _currentRange.cCountPass * 1 + scoreResults.filter(item => item.loading_source === 'cloud-storage').length
                _currentRange.bCountFail = _currentRange.bCountFail * 1 + errorResults.filter(item => item.loading_source === 'big-query').length
                _currentRange.cCountFail = _currentRange.cCountFail * 1 + errorResults.filter(item => item.loading_source === 'cloud-storage').length
                _currentRange.bCountGood = _currentRange.bCountGood * 1 + Number(scoreResults.filter(item => item.category === 'good' && item.loading_source === 'big-query').length || 0)
                _currentRange.cCountGood = _currentRange.cCountGood * 1 + Number(scoreResults.filter(item => item.category === 'good' && item.loading_source === 'cloud-storage').length || 0)
                _currentRange.bCountAvg = _currentRange.bCountAvg * 1 + Number(scoreResults.filter(item => item.category === 'average' && item.loading_source === 'big-query').length || 0)
                _currentRange.cCountAvg = _currentRange.cCountAvg * 1 + Number(scoreResults.filter(item => item.category === 'average' && item.loading_source === 'cloud-storage').length || 0)
                _currentRange.bCountBelowAvg = _currentRange.bCountBelowAvg * 1 + Number(scoreResults.filter(item => item.category === 'below-average' && item.loading_source === 'big-query').length || 0)
                _currentRange.cCountBelowAvg = _currentRange.cCountBelowAvg * 1 + Number(scoreResults.filter(item => item.category === 'below-average' && item.loading_source === 'cloud-storage').length || 0)
            } else {
                const { payload: { nextProduct, results: newResults = [] } } = await dispatch(saveQaResultApi({
                    isIndividual: 0,
                    feedSource: qaReportData.feed_source,
                    databaseCode: qaReportData.database_code,
                    productCode: product.product_code,
                    productUid: product.uid,
                    reportId: qaRangeId,
                    status: r.error || 'error',
                    lastError: `Error in the running the QA: ${product.product_code || product.uid}`,
                }))
                _currentRange.results = [...newResults, ...(_currentRange.results || [])]
                _currentRange.countFail += newResults.length
                nextTable = nextProduct
            }
            if(_totalScore){
                setTotalScore(Number(_totalScore).toFixed(2))
            }
            _currentRange.progress = Number(_currentRange.progress || 0) + 1
            // console.log(_currentRange, '_currentRange end')
            setCurrentRange({
                ..._currentRange
            })

            if (pre.totalProgress !== undefined) {
                setTotalProgress(pre.totalProgress + 1)
            }
            console.log(_currentRange, productCountsPerRange, 'here>>>')
            if (nextTable && keepQaRef.current && _currentRange.progress < productCountsPerRange) {
                // console.log(_currentRange, 'before qaprocess recuirsive')
                return await qaProcess(nextTable, qaRangeId, rangeIndex, processType, {
                    currentRange: {
                        ..._currentRange
                    },
                    totalScore: _totalScore,
                    totalProgress: pre.totalProgress + 1
                })
            }
        }
        if (keepQaRef.current) {
            if ((rangeIndex <= rangeCount && (_currentRange.progress == productCountsPerRange || !nextTable)) || ['all', 'error'].includes(processType)) {
                setQaProcessLoading(false)
                const { range = {}, nextRange: _nextRange } = await saveQARange({
                    id: qaRangeId,
                    qaReportId: qaReportData.id,
                    endProduct: product.uid,
                    totalRange: rangeCount,
                    progressedCount: _currentRange.progress,
                    score: _currentRange.score,
                    moveNext: processType === '' ? 1 : 0
                })
                console.log('>>>>>>ProcessType', processType)
                modifiedRange = { ...range }
                modifiedRange.score = _currentRange.score
                modifiedRange.b_pass_count = _currentRange.bCountPass
                modifiedRange.c_pass_count = _currentRange.cCountPass
                modifiedRange.b_fail_count = _currentRange.bCountFail
                modifiedRange.c_fail_count = _currentRange.cCountFail
                modifiedRange.b_count_of_good = _currentRange.bCountGood
                modifiedRange.c_count_of_good = _currentRange.cCountGood
                modifiedRange.b_count_of_average = _currentRange.bCountAvg
                modifiedRange.c_count_of_average = _currentRange.cCountAvg
                modifiedRange.b_count_of_below_average= _currentRange.bCountBelowAvg
                modifiedRange.c_count_of_below_average= _currentRange.cCountBelowAvg
                if (processType === '') {
                    if (qaReportData.run_type === 'manual') {
                        const _qaRanges = [...qaRanges]
                        const _qIdx = _qaRanges.findIndex(item => item.id === qaRangeId)
                        if (_qIdx > -1) {
                            _qaRanges[_qIdx] = { ...modifiedRange }
                        } else {
                            _qaRanges.push(modifiedRange)
                        }
                        if (_nextRange) {
                            nextRange = {
                                ..._nextRange,
                                score: 0,
                                b_pass_count: 0,
                                c_pass_count: 0,
                                b_fail_count: 0,
                                c_fail_count: 0,
                                b_count_of_good: 0,
                                c_count_of_good: 0,
                                b_count_of_average: 0,
                                c_count_of_average: 0,
                                b_count_of_below_average: 0,
                                c_count_of_below_average: 0,
                            }
                            _qaRanges.push({
                                ...nextRange
                            })
                        }
                        setQARanges([..._qaRanges])
                        if (rangeIndex == rangeCount) {
                            dispatch(showSnackbarMessage({
                                message: `Quality Analytic successfully Done!`,
                            }))
                        }
                    }

                    // console.log(nextRange, 'nextRange')
                    nextRange = _nextRange
                } else {
                    dispatch(showSnackbarMessage({
                        message: `Quality Analytic successfully Done!`,
                    }))
                }
            }
        }
        initCurrentRange({})
        setResults(_currentRange.results || [])
        qaProcessRef.current = {
            qaRangeId: 0,
            productId: 0,
            loading: false,
            progress: 0
        }
        if(rangeCount == rangeIndex) nextRange = undefined
        return {
            range: modifiedRange,
            nextRange,
            product,
            totalScore: _totalScore,
            totalProgress: pre.totalProgress + 1
        }
    }

    useEffect(() => {
        qaProcessRef.current = {
            ...qaProcessRef.current,
            loading: qaProcessLoading
        }
    }, [
        qaProcessLoading
    ])

    const startAutomatic = async (_initRangeIndex, pre = null) => {
        if (qaProcessLoading) return
        setQaProcessLoading(true)
        const _ranges = [...qaRanges]
        let range, _currentRange
        if(pre){
            range = pre.range
            _currentRange = {...pre.current, id: range.id}
        }else{
            range = await initQARange({
                qaReportId,
                rangeIndex: _initRangeIndex
            })
            if(range){
                _ranges.push(range)
                _currentRange = {...currentRange, id: range.id, progress: 0, score: 0}
                setCurrentRange({..._currentRange})
            }
        }
        if (range) {
            let rangeProduct = range.product
            let rangeId = range.id
            let rangeIndex = range.range_index
            let _totalScore = totalScore || 0
            let _totalProgress  = totalProgress || 0
            while (true) {
                if (!keepQaRef.current) break
                if (rangeIndex <= rangeCount) {
                    const returns = await qaProcess(rangeProduct, rangeId, rangeIndex, '', {
                        currentRange: { ..._currentRange },
                        totalProgress: _totalProgress,
                        totalScore: _totalScore
                    })
                    const { range: prevRange, nextRange }= returns
                    // console.log(returns, 'returns')
                    // console.log(_ranges, '_ranges')
                    _totalScore = returns.totalScore
                    _totalProgress = returns.totalProgress
                    if (prevRange) {
                        const _qIdx = _ranges.findIndex(item => item.id == prevRange.id)
                        if (_qIdx > -1) {
                            _ranges[_qIdx] = { ...prevRange }
                        } else {
                            _ranges.push(prevRange)
                        }
                    }
                    // console.log(prevRange, 'prevRange')
                    setQARanges(_ranges)
                    // console.log(keepQaRef.current, 'keepqaRef.current')
                    if (rangeIndex == rangeCount) {
                        initCurrentRange({})
                        // _ranges[_ranges.length - 1].updated
                        dispatch(showSnackbarMessage({
                            message: `Quality Analytic successfully Done!`,
                        }))
                        break
                    }else if (nextRange) {
                        _ranges.push(nextRange)
                        setQARanges(_ranges)
                        // console.log(_ranges, '_ranges')
                        rangeProduct = nextRange.product
                        rangeId = nextRange.id
                        rangeIndex = nextRange.range_index
                        _currentRange = initCurrentRange({
                            id: rangeId
                        })
                        // break
                    } else {

                    }
                } else {
                    break
                }
            }
        }
        setQaProcessLoading(false)
        return
    };
    const startManualInterval = async (_initRangeIndex) => {
        if (qaProcessLoading) return
        setQaProcessLoading(true)
        const range = await initQARange({
            qaReportId,
            rangeIndex: _initRangeIndex
        })
        setCurrentRange((prev) => ({ ...prev, id: range.id }))
        await qaProcess(range.product, range.id, range.range_index, '', {
            currentRange: { ...currentRange, id: range.id },
            totalProgress,
            totalScore
        })
        setQaProcessLoading(false)
    };
    const retryQA = async (qaRangeId, rType) => {
        try {
            if (qaProcessLoading) return
            setSelectedRangeId(qaRangeId)
            setQaProcessLoading(true)
            const { errorProducts, nextProduct } = await retryQARange({ qaRangeId, rType })
            const _qaRanges = [...qaRanges]
            const _qIdx = _qaRanges.findIndex(item => item.id == qaRangeId)
            if (rType === 'all') {
                const _currentRange = initCurrentRange({ id: qaRangeId })
                setCurrentRange(_currentRange)
                _qaRanges[_qIdx].score = 0
                _qaRanges[_qIdx].b_pass_count = 0
                _qaRanges[_qIdx].c_pass_count = 0
                _qaRanges[_qIdx].b_fail_count = 0
                _qaRanges[_qIdx].c_fail_count = 0
                _qaRanges[_qIdx].b_count_of_good = 0
                _qaRanges[_qIdx].c_count_of_good = 0
                _qaRanges[_qIdx].b_count_of_average = 0
                _qaRanges[_qIdx].c_count_of_average = 0
                _qaRanges[_qIdx].b_count_of_below_average= 0
                _qaRanges[_qIdx].c_count_of_below_average= 0
                setQARanges([..._qaRanges])
                const { range } = await qaProcess(nextProduct, qaRangeId, 0, 'all', {
                    currentRange: _currentRange,
                    totalScore,
                    totalProgress
                })
                _qaRanges[_qIdx] = {...range}
                setQARanges([..._qaRanges])
                initCurrentRange({})
            } else {
                const rangeResults = await getQaResults(qaRangeId)
                setCurrentRange((prev) => ({
                    ...prev,
                    id: qaRangeId,
                    results: rangeResults
                }))
                let score = Number(currentRange.score || 0)
                let bCountPass = Number(currentRange.bCountPass || 0)
                let cCountPass = Number(currentRange.cCountPass || 0)
                let bCountFail = 0, cCountFail = 0
                let bCountGood = Number(currentRange.bCountGood || 0)
                let cCountGood = Number(currentRange.cCountGood || 0)
                let bCountAvg = Number(currentRange.bCountAvg || 0)
                let cCountAvg = Number(currentRange.cCountAvg || 0)
                let bCountBelowAvg = Number(currentRange.bCountBelowAvg || 0)
                let cCountBelowAvg = Number(currentRange.cCountBelowAvg || 0)
                let _currentResults = [...rangeResults]
                for (let product of errorProducts) {
                    if (!keepQaRef.current) break;
                    _currentResults = _currentResults.filter(item => item.product_code !== product.product_code)
                    const r = await runQA(product.uid, qaRangeId);
                    if (r && r.payload) {
                        const { results: newResults = [] } = r.payload
                        const scoreResults = newResults.filter(item => item.score)
                        let newScore = 0
                        if (scoreResults.length > 0) {
                            const sum = scoreResults.reduce((acc, obj) => acc + obj.score, 0);
                            newScore = sum / scoreResults.length
                            if (score > 0) {
                                newScore = (parseFloat(newScore) + parseFloat(score)) / 2
                            }
                            score = newScore
                            setTotalScore((prev) => {
                                let avg = sum / scoreResults.length
                                if (prev > 0) {
                                    // console.log(prev, avg, 'here???')
                                    avg = (parseFloat(avg) + parseFloat(prev)) / 2
                                }
                                return avg.toFixed(2)
                            })
                        }
                        _currentResults = [...newResults, ..._currentResults]
                        bCountPass += scoreResults.filter(item => item.loading_source === 'big-query').length
                        cCountPass += scoreResults.filter(item => item.loading_source === 'cloud-storage').length
                        bCountFail += _currentResults.filter(item => item.loading_source === 'big-query').length - bCountPass
                        cCountFail += _currentResults.filter(item => item.loading_source === 'cloud-storage').length - bCountPass
                        bCountGood += Number(scoreResults.filter(item => item.category === 'good' && item.loading_source === 'big-query').length || 0)
                        cCountGood += Number(scoreResults.filter(item => item.category === 'good' && item.loading_source === 'cloud-storage').length || 0)
                        bCountAvg += Number(scoreResults.filter(item => item.category === 'average' && item.loading_source === 'big-query').length || 0)
                        cCountAvg += Number(scoreResults.filter(item => item.category === 'average' && item.loading_source === 'cloud-storage').length || 0)
                        bCountBelowAvg += Number(scoreResults.filter(item => item.category === 'below-average' && item.loading_source === 'big-query').length || 0)
                        bCountBelowAvg += Number(scoreResults.filter(item => item.category === 'below-average' && item.loading_source === 'cloud-storage').length || 0)

                        setCurrentRange((prev) => ({
                            ...prev,
                            score: (newScore > 0 ? newScore : score).toFixed(2),
                            results: _currentResults,
                            bCountPass,
                            cCountPass,
                            bCountFail,
                            cCountFail,
                            bCountGood,
                            cCountGood,
                            bCountAvg,
                            cCountAvg,
                            bCountBelowAvg,
                            cCountBelowAvg,
                        }))
                    } else {
                        const { payload: { results: newResults = [] } } = await dispatch(saveQaResultApi({
                            isIndividual: 0,
                            feedSource: qaReportData.feed_source,
                            databaseCode: qaReportData.database_code,
                            productCode: product.product_code,
                            productUid: product.uid,
                            reportId: qaRangeId,
                            status: r.error || 'error',
                            lastError: `Error in the running the QA: ${product.product_code || product.uid}`,
                        }))
                        bCountFail += newResults.filter(item => item.loading_source === 'big-query').length
                        cCountFail += newResults.filter(item => item.loading_source === 'cloud-storage').length
                        _currentResults = [...newResults, ..._currentResults]
                        setCurrentRange((prev) => ({
                            ...prev,
                            bCountFail,
                            cCountFail,
                            results: _currentResults
                        }))
                    }
                }
                _qaRanges[_qIdx].score = score
                _qaRanges[_qIdx].b_pass_count = bCountPass
                _qaRanges[_qIdx].c_pass_count = cCountPass
                _qaRanges[_qIdx].b_fail_count = bCountFail
                _qaRanges[_qIdx].c_fail_count = cCountFail
                _qaRanges[_qIdx].b_count_of_good = bCountGood
                _qaRanges[_qIdx].c_count_of_good = cCountGood
                _qaRanges[_qIdx].b_count_of_average = bCountAvg
                _qaRanges[_qIdx].c_count_of_average = cCountAvg
                _qaRanges[_qIdx].b_count_of_below_average= bCountBelowAvg
                _qaRanges[_qIdx].c_count_of_below_average= cCountBelowAvg

                setQARanges([..._qaRanges])
                saveQARange({
                    id: qaRangeId,
                    score: score
                })
                setResults(_currentResults)
                initCurrentRange({})
            }
            setQaProcessLoading(false)
        } catch (e) {
            initCurrentRange({})
            setQaProcessLoading(false)
        }
    }
    const resumeQA = async (product, rangeId, rangeIndex) => {
        const { range: newRange } = await saveQARange({
            id: rangeId,
            action: 'resume',
            pausedProduct: product.uid,
            feedSource: qaReportData.feedSource,
            databaseCode: qaReportData.databaseCode
        })
        const rangeResults = await getQaResults(rangeId)
        const rIdx = qaRanges.findIndex(item => item.id == rangeId)
        const range = {...qaRanges[rIdx], ...newRange}
        const _qaRanges = [...qaRanges]
        _qaRanges[rIdx] = range
        setQARanges([..._qaRanges])
        const _currentRange = {
            id: rangeId,
            progress: range.progressed_count || 0,
            score: range.score,
            results: rangeResults || [],
            bCountPass: range.b_pass_count,
            cCountPass: range.c_pass_count,
            bCountFail: range.b_fail_count,
            cCountFail: range.c_fail_count,
            bCountAvg: range.b_count_of_average,
            cCountAvg: range.c_count_of_average,
            bCountGood: range.b_count_of_good,
            cCountGood: range.c_count_of_good,
            bCountBelowAvg: range.b_count_of_below_average,
            cCountBelowAvg: range.c_count_of_below_average,
        }
        setCurrentRange({
            ..._currentRange
        })
        if(qaReportData.run_type === 'automatic'){
            await startAutomatic(rangeIndex, {
                range: { ...range, product },
                current: _currentRange
            })
        }else{
            await qaProcess(product, rangeId, rangeIndex, '', {
                currentRange: _currentRange,
                totalScore,
                totalProgress
            })
        }
    }

    useEffect(() => {
        const handleBeforeUnload = async (event) => {
            if (qaProcessRef.current.loading) {
                // Perform any necessary actions before the page is unloaded
                // For example, you can show a confirmation dialog
                const confirmationMessage = 'Qa process has been paused!';
                event.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
                keepQaRef.current = false
                saveQARange({
                    id: qaProcessRef.current.qaRangeId,
                    pausedProduct: qaProcessRef.current.productId,
                    progressedCount: qaProcessRef.current.progress
                })
                setTimeout(() => {
                    dispatch(showSnackbarMessage({
                        message: 'QA process has been paused. Please resume it',
                        severity: "warning"
                    }))
                    keepQaRef.current = true
                }, 2000)
                return confirmationMessage; // Gecko, WebKit, Chrome <34
            }
        }
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <Box className="p-3 pt-5">
            <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
                <Typography className={classes.TableTitle}>Total Score: {totalScore || 'N/A'}</Typography>
            </Box>
            <TableContainer className={classes.root}>
                <Box display="flex" className={classes.TableCap}>
                    <Box display={"flex"} sx={{ flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box>
                            <Box display="flex" alignItems="center">
                                <Typography className={classes.TableTitle}>{`${qaReportData.feed_source}/${qaReportData.database_code}`}</Typography>
                                <Typography className={`${classes.TableTitle}`}>{`(${qaReportData.run_type})`}</Typography>
                            </Box>
                            <Box display={'flex'}>
                                <div className={`${classes.ColorCap} success`}>Success</div>
                                <div className={`${classes.ColorCap} warning`}>Warn</div>
                                <div className={`${classes.ColorCap} danger`}>Danger</div>
                            </Box>
                        </Box>
                        {/* <Chip label={rows.length} /> */}
                        <Box display={"flex"} sx={{ alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'end' }} className={"mr-3"}>
                                <Tooltip title="Cloud Storage">
                                    <Box
                                        sx={{ padding: '10px !important' }}
                                        className={`${classes.productStaticBarButton} ${blobOpt === 'cloud-storage' ? 'selected' : ''}`}
                                        mr={1}
                                        onClick={() => setBlobOpt('cloud-storage')}
                                    >CS</Box>
                                </Tooltip>
                                <Tooltip title="Big Query">
                                    <Box
                                        sx={{ padding: '10px !important' }}
                                        className={`${classes.productStaticBarButton} ${blobOpt === 'big-query' ? 'selected' : ''}`}
                                        onClick={() => setBlobOpt('big-query')}
                                    >BG</Box>
                                </Tooltip>
                            </Box>
                            <Box className={"mr-3"}>
                                <FormControlLabel
                                    control={
                                        <Switch checked={showMiliSeconds} size="small" onChange={(e) => { setShowMiliSeconds(e.target.checked) }} name="showms" />
                                    }
                                    className='m-0'
                                    label="Show MS"
                                />
                            </Box>
                            <Box className={"mr-3"}>
                                <FormControlLabel
                                    control={
                                        <Switch checked={showTimePercentage} size="small" onChange={(e) => { setShowTimePercentage(e.target.checked) }} name="showtimePercentage" />
                                    }
                                    className='m-0'
                                    label="Show %"
                                />
                            </Box>
                            <Box className="mr-3">
                                <Button
                                    size="small"
                                    color="error"
                                    disabled={totalProgress > 0 || !qaReportData.id || qaRanges.length > 0}
                                    variant='contained'
                                    onClick={() => {
                                        if (qaReportData.run_type === 'automatic') {
                                            startAutomatic(1)
                                        } else {
                                            startManualInterval(1)
                                        }
                                    }}>Start</Button>
                            </Box>
                            <Box>
                                <Tooltip title="Back">
                                    <IconButton disabled={loadingQARanges || qaProcessLoading} onClick={async () => {
                                        if(qaProcessLoading){
                                            keepQaRef.current = false
                                            await saveQARange({
                                                id: qaProcessRef.current.qaRangeId,
                                                pausedProduct: qaProcessRef.current.productId,
                                                progressedCount: qaProcessRef.current.progress
                                            })
                                            dispatch(showSnackbarMessage({
                                                message: 'QA process has been paused!',
                                                severity: "warning"
                                            }))
                                        }
                                        history.push(`/qa/${qaReportData.feed_source}/${qaReportData.database_code}`)
                                    }}>
                                        <Reply />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box>
                    {/* <Typography className={classes.TableSubTitle}>{subTitle}</Typography> */}
                </Box>
                <ResultStatusArea
                    total={qaReportData.total_products || 0}
                    completedCount={totalProgress}
                    ongoingQACount={rangeCount}
                    rangePercentage={qaReportData.range_percentage}
                    selectedRangeId={selectedRangeId}
                    results={rangeRows}
                    onClickConfirm={async (product, rangeId, rangeIndex) => {
                        if (qaProcessLoading) return
                        setQaProcessLoading(true)
                        const _currentRange = {...currentRange, id: rangeId}
                        await qaProcess(product, rangeId, rangeIndex, '', {
                            currentRange: _currentRange,
                            totalProgress,
                            totalScore
                        })
                        setQaProcessLoading(false)
                    }}
                    onClickRange={async (newRangeId) => {
                        setSelectedRangeId(newRangeId)
                        // console.log(newRangeId, 'newRangeId')
                        const rangeResults = await getQaResults(newRangeId)
                        console.log(rangeResults, 'rangeResults')
                        setResults(rangeResults || [])
                    }}
                    onClickResume={resumeQA}
                    onClickRetry={retryQA}
                />
                <Table sx={{ minWidth: 650 }} aria-label="simple table" className={classes.Table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Product Code</TableCell>
                            <TableCell>
                                <Box display={'flex'}>
                                    <span>Status</span>
                                    <IconButton
                                        aria-label="delete"
                                        className={classes.margin}
                                        size="small"
                                        onClick={onOpenFilterPop('status')}>
                                        <ExpandMoreIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>
                            </TableCell>
                            <TableCell>TimeIncrement</TableCell>
                            <TableCell align="right">Asset Load Time</TableCell>
                            <TableCell align="right">Meta Data Load Time</TableCell>
                            <TableCell align="right">Blob Load Time</TableCell>
                            <TableCell align="right">Draw Time</TableCell>
                            <TableCell align="right">Total Time</TableCell>
                            <TableCell align="right">
                                <Box display={'flex'}>
                                    <span>Score</span>
                                    <IconButton
                                        aria-label="delete"
                                        className={classes.margin}
                                        size="small"
                                        onClick={onOpenFilterPop('score')}>
                                        <ExpandMoreIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            loading ? (
                                <TableRow>
                                    <TableCell colSpan={9}><Box className={"text-center"}>Loading...</Box></TableCell>
                                </TableRow>
                            ) : (
                                sortedResults?.length > 0 ? (
                                    <>
                                        {sortedResults.map((item, idx) => (
                                            // row.items?.map((item, iIdx) => (
                                            <TableRow
                                                key={idx}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <Tooltip title="Product Code">
                                                        <Box>
                                                            {item.product_code}
                                                        </Box>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        item.category == 503 ?
                                                          <Tooltip title="QA service was unavailable">
                                                            <Chip size='small' label="Failed" color="error" />
                                                          </Tooltip> :
                                                        item.category == 404 ?
                                                          <Tooltip title="There is no available data">
                                                              <Chip size='small' label="No Data" color="error" />
                                                          </Tooltip> :
                                                          item.last_error && item.last_error.trim() !== '' ?
                                                            <Tooltip title={item.last_error}>
                                                                <Chip size='small' label="Error" color="error" />
                                                            </Tooltip> : (
                                                                item.category === 'good' ? <Chip size='small' label="Good" color="primary" /> :
                                                                    item.category === 'average' ? <Chip size='small' label="Average" color="info" /> :
                                                                        <Chip size='small' label="Below Average" color="warning" />
                                                            )
                                                    }
                                                    {/* // row.staging === 0 ? <Chip size='small' label="In Progress" color="warning" /> :
                                                //     row.staging === -1 ? <Chip size='small' label="Error" color="error" /> :
                                                //         <Chip size='small' label="Done" color="primary" /> */}
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title="Time Increment">
                                                        <Box>
                                                            {item.time_increment}
                                                        </Box>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="right" className={evaluateThreshold('MAX_AL', item.initial_load_seconds)}>
                                                    <Tooltip title="Asset Load Time">
                                                        <Box>
                                                            {
                                                                showTimePercentage ?
                                                                    getPercentageOfTotal(item.total_seconds, item.initial_load_seconds) :
                                                                    convertToSeconds(item.initial_load_seconds, showMiliSeconds)
                                                            }
                                                        </Box>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="right" className={evaluateThreshold('MAX_MDL', item.metadata_load_seconds)}>
                                                    <Tooltip title="Meta Data Load Time">
                                                        <Box>
                                                            {
                                                                showTimePercentage ?
                                                                    getPercentageOfTotal(item.total_seconds, item.metadata_load_seconds) :
                                                                    convertToSeconds(item.metadata_load_seconds, showMiliSeconds)}
                                                        </Box>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="right" className={evaluateThreshold('MAX_BDL', item.blob_load_seconds)}>
                                                    <Tooltip title="Blob Load Time">
                                                        <Box>
                                                            {
                                                                showTimePercentage ?
                                                                    getPercentageOfTotal(item.total_seconds, item.blob_load_seconds) :
                                                                    convertToSeconds(item.blob_load_seconds, showMiliSeconds)}
                                                        </Box>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="right" className={evaluateThreshold('MAX_TDT', item.time_to_draw_seconds)}>
                                                    <Tooltip title="Time to Draw">
                                                        <Box>
                                                            {
                                                                showTimePercentage ?
                                                                    getPercentageOfTotal(item.total_seconds, item.time_to_draw_seconds) :
                                                                    convertToSeconds(item.time_to_draw_seconds, showMiliSeconds)}
                                                        </Box>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="right" className={evaluateThreshold('TOTAL', item.total_seconds)}>
                                                    <Tooltip title="Total Time">
                                                        <Box>{convertToSeconds(item.total_seconds, showMiliSeconds)}</Box>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {
                                                        !(item.last_error && item.last_error.trim() !== '') && (
                                                            <Tooltip title="Score">
                                                                <Box>{item.score.toFixed(2)}</Box>
                                                            </Tooltip>
                                                        )
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            // ))
                                        ))}
                                    </>
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={9}><Box className={"text-center"}>There is no Data</Box></TableCell>
                                    </TableRow>
                                )
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <DataTableColumnFilterPopover
                open={openFilterPop}
                anchorEl={fAnchorEl}
                handleClose={handleClose}
                handleSelection={(items) => {
                    console.log(items, 'items')
                }}
                hasFilter={false}
                direction={columnSort[openedColumn] || null}
                filterValues={[]}
                handleSortClick={(direction) => {
                    setColumnSort({
                        ...columnSort, [openedColumn]: direction
                    })
                }}
            />
        </Box>
    );
}