import * as React from 'react';
import {useMemo} from "react";
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from "react-redux";
import ButtonGroup from '@mui/material/ButtonGroup';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import {Typography} from "antd";
import {useProductContext} from "providers/product/ProductProvider";
import {saveChart, setProductOpt} from "store/slices/StockProductSlice";
import {Box} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {showSnackbarMessage} from "../../../store/slices/MessagesSystemSlice";

const useStyles = makeStyles(() => ({
  chartOpt: {
    fontSize: '11px',
    padding: '0 9px',
    height: '30px',
  }
}))

export default function SaveChartBtn() {
  const dispatch = useDispatch()
  const { feedSource, databaseCode, productCode } = useProductContext()
  const classes = useStyles()
  const user = useSelector(state => state.user.user)
  const {
    xAxis,
    series,
    fields,
    categoryField,
    chartSettings,
    dataLabelsData,
    tooltipFormatData,
    annotations,
    marketChartType,
    nonMarketChartType,
    plotHighlights,
    selectedDataOpt,
    yAxis,

    selectedChart = '',
    charts : {
      innerChart,
      globalChart
    },
    showSidebar,
    loadingSaveChart = false,
  } = useSelector(state => state.productData)

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const onClickChartNav = (opt) => {
    dispatch(setProductOpt({
      path: 'selectedChart', value: opt
    }))
    if(opt === 'inner'){
      // dispatch(setInitialChartConfigs(innerChart.configs))
    }else{
      // console.log(globalChart, 'globalChart')
      // dispatch(setInitialChartConfigs(globalChart.configs))
    }
  }
  const saveChartConfig = (isGlobal  = false) => {
    setOpen(false)
    let innerIsGlobal = isGlobal
    const configs =  {
      marketChartType,
      nonMarketChartType,
      xAxis,
      yAxis,
      series,
      categoryField,
      chartSettings,
      dataLabelsData,
      tooltipFormatData,
      plotHighlights,
      selectedDataOpt,
    }
    if(!innerIsGlobal){
      configs.annotations = annotations
      if(selectedChart === 'global'){
        innerIsGlobal = true
      }
    }
    dispatch(saveChart({
      feedSource,
      databaseCode,
      productCode,
      uid: user.uid,
      configs,
      isGlobal: innerIsGlobal
    })).then(r => {
      const { row, status } = r.payload
      if(status){
        if(innerIsGlobal){
          dispatch(setProductOpt({
            path: 'charts.globalChart', value: row
          }))
          dispatch(showSnackbarMessage({
            severity: 'success',
            message: "Chart settings have been successfully saved across all product-code of " + databaseCode
          }))
        }else{
          dispatch(setProductOpt({
            path: 'charts.innerChart', value: row
          }))
          dispatch(showSnackbarMessage({
            severity: 'success',
            message: "Chart settings have been saved successfully."
          }))
        }
      }
    })
  }

  return (
    <Box display="flex" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
      <ButtonGroup variant="outlined" sx={{ marginLeft: showSidebar ? '0' : '63px' }}>
        <Button
          size="small"
          variant={selectedChart === 'inner' ? "contained" : ''}
          className={classes.chartOpt}
          onClick={() => onClickChartNav('inner')}
        >Inner</Button>
        <Button
          size="small"
          variant={selectedChart === 'global' ? "contained" : ''}
          className={classes.chartOpt}
          disabled={!globalChart}
          onClick={() => onClickChartNav('global')}
        >Global</Button>
      </ButtonGroup>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <LoadingButton
          size="small"
          color='primary'
          variant="contained"
          onClick={() => saveChartConfig()}
          loading={loadingSaveChart}
        >Save</LoadingButton>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          style={{ minWidth: '20px', padding: 0 }}
          disabled={loadingSaveChart}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem
                    onClick={(event) => {
                      saveChartConfig(true)
                    }}
                  >
                    <Typography style={{ fontSize: '12px' }}>
                      Save as Global
                    </Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}