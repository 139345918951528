import React, { useCallback, useMemo, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setProductOpt, addPlotHighlights, deletePlotHighlights} from "store/slices/StockProductSlice";
import CustomFormInput from "components/common/Base/CustomFormInput";
import BaseAccordion from "../../BaseAccordion";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  Divider,
  Button,
} from "@mui/material";
import {DropdownSelect} from "components/common/Product/DropdownSelect";
import {CustomizeItem, EndNode, StartNode} from "components/common/Product/CustomizeItem";
import ColorPicker from "components/common/ColorPicker";
import {DeleteOutline} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";
import {useProductContext} from "providers/product/ProductProvider";

const useStyles = makeStyles(() => ({
  IconButton: {
    // flexGrow: 1,
    minWidth: 0,
    padding: '4px',
    '& svg': {
      width: '20px',
      height: '20px'
    }
  }
}));

const bgColors = [
  '#fff','#fce4ec', '#ffcdd2', '#f3e5f5', '#e8eaf6', '#e3f2fd', '#d1c4e9',
  '#c5cae9', '#b3e5fc', '#b2ebf2', '#b2dfdb', '#e0f2f1', '#e6ee9c', '#fff9c4',
  '#eeeeee', '#ffccbc'
]
export const AnnotationHighlights = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { chartSettings, plotHighlights = [] } = useSelector(state => state.productData)
  const { defaultHighlights } = useProductContext()

  const [selectedId, setSelectedId] = useState()
  const textOptions = useMemo(() => {
    return plotHighlights.map((item, idx) => {
      return {
        id: item.id,
        label: item?.label?.text && item?.label?.text !== '' ? item?.label?.text : `Highlight ${idx + 1}`
      }
    })
  }, [
    plotHighlights
  ])
  const selectedHighlight = useMemo(() => {
    return plotHighlights.filter(item => item.id === selectedId)[0] || {}
  }, [
    plotHighlights,
    selectedId
  ])
  const onClickAdd = () => {
    if(defaultHighlights){
      dispatch(addPlotHighlights(defaultHighlights))
    }
  }
  const onClickDelete = () => {
    dispatch(deletePlotHighlights(selectedId))
    setSelectedId(null)
  }
  const onChangeBand = useCallback((field, newValue) => {
    const idx = plotHighlights.findIndex(item => item.id === selectedId)
    if(idx > -1){
      if(plotHighlights[idx].isFresh){
        dispatch(setProductOpt({
          path: `plotHighlights.${idx}.isFresh`, value: false
        }))
      }
      dispatch(setProductOpt({
        path: `plotHighlights.${idx}.${field}`, value: newValue
      }))
    }
  }, [
    plotHighlights,
    selectedId
  ])

  return (
    <BaseAccordion
      seriesLabel="Highlights"
      style={{ marginBottom: '10px' }}
    >
      <Box sx={{ display: 'flex', flexFlow: 'column', gap: '10px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, }}>
          <Box sx={{ flex: 1}}>
            <DropdownSelect
              selectedValue={selectedId}
              placeholder={selectedId ? undefined : 'Highlights' }
              onChange={(val) => setSelectedId(val)}
              options={textOptions}
            />
          </Box>
          <Button onClick={onClickAdd} className={classes.IconButton} size='small' variant='contained'>
            <AddCircleIcon />
          </Button>
          <Button onClick={onClickDelete} disabled={!selectedId} className={classes.IconButton} size='small' variant='contained' color='error'>
            <DeleteOutline />
          </Button>
        </Box>
        {
          selectedId && (
            <>
              <Divider />
              <CustomFormInput
                label="Label"
                fieldName="text"
                value={selectedHighlight?.label?.text || ''}
                onChange={(field, value) => onChangeBand('label.text', value)}
              />
              <DropdownSelect
                selectedValue={selectedHighlight?.type || ''}
                placeholder={'Type' }
                onChange={(value) => onChangeBand('type', value)}
                options={[
                  { id: 'band', label: 'Range' },
                  { id: 'line', label: 'Line' },
                ]}
              />
              <DropdownSelect
                selectedValue={selectedHighlight?.axis || ''}
                placeholder={'Orientation' }
                onChange={(value) => onChangeBand('axis', value)}
                options={[
                  { id: 'x', label: 'Horizontal' },
                  { id: 'y', label: 'Vertical' },
                ]}
              />
              {
                selectedHighlight?.type === 'line' && (
                  <DropdownSelect
                    selectedValue={selectedHighlight?.dashStyle || ''}
                    placeholder={'Dash Style' }
                    onChange={(value) => onChangeBand('dashStyle', value)}
                    options={[
                      {id: 'Solid', label: 'Solid'},
                      {id: 'ShortDash', label: 'Short Dash'},
                      {id: 'ShortDot', label: 'Short Dot'},
                      {id: 'ShortDashDot', label: 'Short DashDot'},
                      {id: 'ShortDashDotDot', label: 'Short DashDotDot'},
                      {id: 'Dot', label: 'Dot'},
                      {id: 'Dash', label: 'Dash'},
                      {id: 'LongDash', label: 'Long Dash'},
                      {id: 'DashDot', label: 'Dash Dot'},
                      {id: 'LongDashDot', label: 'LongDashDot'},
                      {id: 'LongDashDotDot', label: 'Long DashDotDot'},
                    ]}
                  />
                )
              }
              <CustomizeItem>
                <StartNode>Label Color</StartNode>
                <EndNode>
                  <span>{selectedHighlight?.label?.style?.color || '#fff'}</span>
                  <ColorPicker
                    color={selectedHighlight?.label?.style?.color}
                    pickerLable={''}
                    handleChange={(color) => {
                      onChangeBand('label.style.color', color.hex)
                    }}
                  />
                </EndNode>
              </CustomizeItem>
              <CustomizeItem>
                <StartNode>Color</StartNode>
                <EndNode>
                  <span>{selectedHighlight?.color || '#fff'}</span>
                  <ColorPicker
                    colors={bgColors}
                    color={selectedHighlight?.color}
                    pickerLable={''}
                    handleChange={(color) => {
                      onChangeBand('color', color.hex)
                    }}
                  />
                </EndNode>
              </CustomizeItem>
              <DropdownSelect
                selectedValue={selectedHighlight?.label?.verticalAlign}
                placeholder={'Vertical align' }
                onChange={(val) => onChangeBand('label.verticalAlign', val)}
                options={[
                  { id: 'top', label: 'Top' },
                  { id: 'middle', label: 'Middle' },
                  { id: 'bottom', label: 'Bottom' },
                ]}
              />
            </>
          )
        }
      </Box>
    </BaseAccordion>
  )
};
