import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {makeStyles} from "@mui/styles";
import Alert from '@mui/lab/Alert';

import {useProductContext} from "providers/product/ProductProvider";
import LineChart from "./chart-types/LineChart";
import PieChart from "./chart-types/PieChart";
import SaveChartBtn from "../../details/SaveChartBtn";

const useStyles = makeStyles(() => ({
  root: {
    // flexGrow: 1,
    height: '100%',
    padding: '10px'
  }
}));

function ChartContainer() {
  const classes = useStyles();
  const { feedSource, databaseCode, productCode, data } = useProductContext()
  const {
    xAxis,
    series,
    fields,
    categoryField,
    chartSettings,
    dataLabelsData,
    tooltipFormatData,
    annotations
  } = useSelector(state => state.productData)

  const xAxisType = useMemo(() => {
    const xAxisField = fields.filter(item => item.id === xAxis)[0]
    if(xAxisField){
      return xAxisField?.type
    }
    return null
  }, [
    fields,
    xAxis
  ])
  const chartType = useMemo(() => {
    const idx = series?.findIndex(item => item.id === 'default')
    if(idx > -1){
      return series[idx]?.chartType || 'line'
    }
    return 'line'
  }, [
    series
  ])

  const alertMessage = useMemo(() => {
    if(chartType === 'pie'){
      if(!(chartType && xAxis && series.length > 0 && categoryField)){
        return 'Please check CategoryField, XAxis, Value and Series object in the Data Panel!'
      }
    }else{
      if(!(chartType && xAxis && series.length > 0)){
        return 'Please check Value and Series object in the Data Panel!'
      }
    }
    return ''
  }, [
    chartType, xAxis, series, categoryField
  ])

  const seriesMessage = useMemo(() => {
    const errors = [];
    for(let serie of series){
      const idx = fields.findIndex(item =>item.id === serie.value)
      if(idx < 0){
        errors.push(`No data is available for the '${serie.value}' column, which is required for the chart series.`)
      }
    }
    return errors
  }, [
    series,
    fields
  ])

  const chartConfigs = useMemo(() => {
    const verticalAxesSetting = chartSettings?.axes?.vertical
    const horizontalAxesSetting = chartSettings?.axes?.horizontal
    return {
      ...chartSettings,
      tooltip: {
        ...chartSettings.tooltip,
        split: true,
        shared: true,
      },
      yAxis: {
        ...horizontalAxesSetting,
        labels: {
          format: `${verticalAxesSetting.input}`,
          style: verticalAxesSetting.style,
        }
      },
      xAxis: {
        ...horizontalAxesSetting,
        labels: {
          format: `${horizontalAxesSetting.input}`,
          style: horizontalAxesSetting.style
        }
      },
      plotOptions: {
        series: {
          showInNavigator: true,
          gapSize: 6,
          allowPointSelect: true,
          // compare: 'value',
          // cumulative: true,
          dataLabels: dataLabelsData?.all || {}
        }
      }
    }
  }, [
    chartSettings,
    dataLabelsData
  ])

  console.log(xAxis, fields, series, 'xAxis')

  if(alertMessage !== ''){
    return <Alert severity="info">{alertMessage}</Alert>
  }

  return (
    <div className={classes.root}>
      <SaveChartBtn />
      {
        seriesMessage.map((item, idx) => (
          <Alert severity="warning" key={idx} style={{ marginTop: '10px' }}>{item}</Alert>
        ))
      }
      {
        chartType === 'pie' ? (
          <PieChart
            data={data}
            path={`${feedSource}/${databaseCode}/${productCode}`}
            xAxis={xAxis}
            xAxisType={xAxisType}
            yAxisFields={series}
            chartType={chartType}
            categoryField={categoryField}
            defaultConfigs={chartConfigs}
            dataLabelsData={dataLabelsData}
            annotations={annotations}
          />
        ) : (
          <LineChart
            data={data}
            path={`${feedSource}/${databaseCode}/${productCode}`}
            xAxis={xAxis}
            xAxisType={xAxisType}
            yAxisFields={series}
            chartType={chartType}
            defaultConfigs={chartConfigs}
            dataLabelsData={dataLabelsData}
            tooltipFormatData={tooltipFormatData}
            annotations={annotations}
          />
        )
      }
    </div>
  );
}

export default  ChartContainer
