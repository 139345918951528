import React, { useRef, useMemo } from "react";
import { useSelector, useDispatch } from 'react-redux'
import Highcharts from 'highcharts/highstock'

import StockHighChart from "views/product/details/chart/StockHighChart";
import {addAnnotation, setProductOpt, updatePlotHighlights} from "store/slices/StockProductSlice";

const useData = (initialData, xAxis = null, yAxisFields = []) => {


  let defaultSeries = [], volume = [], duelSeries = {}
  const _dateField = xAxis || 'date'

  // const pointList = ['open', 'low', 'high', 'close']
  if (!(xAxis && yAxisFields && yAxisFields.length > 0)) return [{}]
  // if (yAxisFields?.length > 0) {
  //   console.log(yAxisFields, 'yAxisfields')
  //   yAxisFields.map((chartLine, idx) => {
  //     duelSeries[`Series_${idx}`] = {
  //       name: `Series ${idx}`,
  //       color: getRandomColor(),
  //       data: initialData.map(item => {
  //         const t = new Date(item[_dateField])
  //         const pointDate = t.getTime();
  //         let seriesPoint = [
  //           pointDate
  //         ]
  //         for(let pointField of pointList){
  //           seriesPoint.push(item[chartLine[pointField]])
  //         }
  //         return seriesPoint
  //       })
  //     }
  //   })
  // }

  // for (let idx in yAxisFields){
  //   const yField = yAxisFields[idx]
  //   if(idx === 0){
  //     duelSeries['ohlc'] =  initialData.map(item => {
  //       const t = new Date(d[_dateField])
  //       const pointDate = t.getTime();
  //       let seriesPoint = [
  //         pointDate
  //       ]
  //     })
  //   }
  // }

  for (let d of initialData) {
    const t = new Date(d[_dateField])
    const pointDate = t.getTime();
    let seriesPoint = []
    yAxisFields.map((field) => {
      let duelKey = field.value
      // if(['ohlc', 'range'].includes(field.type)){
      //   duelKey = 'default'
      // }
      if(field.id === 'default'){
        duelKey = 'default'
      }

      if (duelSeries[duelKey] === undefined) {
        duelSeries[duelKey] = {
          ...field,
          name: duelKey.toUpperCase(),
          // color: getRandomColor(),
          data: [],
          type: field.chartType || 'line',
          lineWidth: 2,
        }
        if(field.chartType === 'point-marker'){
          duelSeries[duelKey].type = 'scatter'
          duelSeries[duelKey].marker = {
            enabled: true,
            radius: 2
          }
          duelSeries[duelKey].lineWidth = 0
        }
        if(field?.marker?.symbol && field?.marker?.symbol !== 'none'){
          duelSeries[duelKey].marker = {
            enabled: true,
            symbol: field?.marker?.symbol,
            radius: 3
          }
        }
        // if(field.type === 'ohlc'){
        //   duelSeries[duelKey].data = {
        //     open: [],
        //     high: [],
        //     low: [],
        //     close: []
        //   }
        // }
      }
      if(field.type === 'ohlc'){
        seriesPoint = [pointDate, d[field.open], d[field.high], d[field.low], d[field.close]]
        duelSeries[duelKey].data.push([pointDate, d[field.open], d[field.high], d[field.low], d[field.close]])
        // duelSeries[duelKey].data.open.push([pointDate, d[field.open]])
        // duelSeries[duelKey].data.high.push([pointDate, d[field.high]])
        // duelSeries[duelKey].data.low.push([pointDate, d[field.low]])
        // duelSeries[duelKey].data.close.push([pointDate, d[field.close]])
      }else if(field.type === 'range'){
        duelSeries[duelKey].data.push([pointDate, d[field.lower] || 0, d[field.higher] || 0])
      }else{
        duelSeries[duelKey].data.push([pointDate, d[field.value]])
      }
    })
    // defaultSeries.push(seriesPoint)
    if(d.volume){
      volume.push([
        pointDate, d.volume
      ])
    }
  }
  let data = duelSeries
  return {
    data, volume
  }
}
const useSeries = ({
  data: seriesData,
  volume,
  config,
  tooltip = {},
  dataLabels = {}
}) => {
  if (!seriesData) return []
  const {
    step, spline, dashStyle, zones: initialZones
  } = config
  let { scatterEnabled, scatterRadius, shadow } = config
  let chartType = seriesData['default'].type || 'line'
  let useOhlcData = false, groupingUnits = [
    [
      'week', // unit name
      [1] // allowed multiples
    ], [
      'month',
      [1, 2, 3, 4, 6]
    ]
  ], lineWidth = 2
  if (chartType === 'scatter-line') {
    chartType = 'scatter'
    scatterEnabled = true
    scatterRadius = 2
  }
  if (chartType === 'line' && spline) {
    chartType = 'spline'
  } else if (chartType === 'area' && spline) {
    chartType = 'areaspline'
  } else if (chartType === 'arearange' && spline) {
    chartType = 'areasplinerange'
  } else if (chartType === 'bellcurve') {
    chartType = 'scatter'
  } else if (chartType === 'point-marker') {
    chartType = 'line'
    lineWidth = 0
    scatterEnabled = true
    scatterRadius = 2
    shadow = false
  } else if (chartType === 'ohlc') {
    useOhlcData = true
  } else if (chartType === 'grouped-bar' || chartType === 'stacked-bar') {
    chartType = 'column'
  } else if (chartType === 'columnpyramid-stack' || chartType === 'columnpyramid-grouped') {
    chartType = 'columnpyramid'
  } else if (chartType === 'percent-area') {
    chartType = 'area'
  }
  const defaultData = seriesData.default ? seriesData.default : seriesData;
  let seriesList = []
  // console.log(dataLabels, 'datalabels')
  // console.log(seriesData, 'seriesData')
  for(const key in seriesData){
    if(['default'].indexOf(key) < 0){
      const formatBody = tooltip[key] || tooltip['all']
      const seriesItem = {
        ...seriesData[key],
        tooltip: {
          valueDecimals: 2,
          pointFormat: `${formatBody.pointFormatBody}`
        },
        // dataLabels: {
        //   enabled: !!dataLabels[key]?.enabled
        // }
      }
      if(dataLabels[key]){
        seriesItem.dataLabels = dataLabels[key]
      }
      seriesList.push(seriesItem)
    }
  }
  const stockTooltip = tooltip['stock'] || tooltip['all']
  const volumeTooltip = tooltip['volume'] || tooltip['all']
  let stockSeries = {
      ...seriesData['default'],
      name: 'Stock',
      type: chartType,
      id: 'stockseries',
      // type: 'gantt',
      lineWidth: lineWidth,
      shadow: shadow,
      step: step,
      data: seriesData?.default?.data || seriesList[0].data,
      tooltip: {
        valueDecimals: 2,
        pointFormat: `${stockTooltip.pointFormatHeader}: <br /> ${stockTooltip.pointFormatBody}`
      },
      dataGrouping: {
        // units: groupingUnits
      },
      states: {
        hover: {
          lineWidthPlus: 0
        }
      },
      zones: [...initialZones],
      useOhlcData: useOhlcData,
    },
    volumeSeries = {
      ...seriesData['volume'],
      type: 'column',
      name: 'Volume',
      data: volume,
      dataLabels: dataLabels['volume'] ? dataLabels['volume'] : {},
      yAxis: 1,
      tooltip: {
        valueDecimals: 2,
        pointFormat: `${volumeTooltip.pointFormatBody}`
      },
      dataGrouping: {
        // units: groupingUnits
      }
    }
  if(dataLabels['stock']){
    stockSeries.dataLabels = dataLabels['stock']
  }
  if(dataLabels['volume']){
    volumeSeries.dataLabels = dataLabels['volume']
  }else{
    volumeSeries.dataLabels = dataLabels['all']
  }
  if (chartType === 'bellcurve') {
    return [
      {
        name: 'Bell Curve',
        type: 'bellcurve',
        xAxis: 0,
        yAxis: 0,
        baseSeries: 1,
        zIndex: -1,
        intervals: 4,
        pointsInInterval: 5
      },
      {
        ...stockSeries,
        accessibility: {
          exposeAsGroupOnly: true
        },
        marker: {
          radius: 1.5
        }
      },
      volumeSeries
    ]
  } else if (['grouped-bar', 'stacked-bar', 'columnpyramid-stack', 'columnpyramid-grouped', 'percent-area'].indexOf(chartType) > -1) {
    const initialSeries = { ...stockSeries, id: undefined, data: [] }
    const oSeries = { ...initialSeries, id: 'stockseries', name: 'Open', color: Highcharts.getOptions().colors[0] },
      hSeries = { ...initialSeries, name: 'High', color: Highcharts.getOptions().colors[1] },
      lSeries = { ...initialSeries, name: 'Low', color: Highcharts.getOptions().colors[2] },
      cSeries = { ...initialSeries, name: 'Close', color: Highcharts.getOptions().colors[3] }
    for (let d of seriesData.ohlc) {
      oSeries.data = seriesData.ohlc.data.open || []
      hSeries.data = seriesData.ohlc.data.high || []
      lSeries.data = seriesData.ohlc.data.low || []
      cSeries.data = seriesData.ohlc.data.close || []
    }
    return [oSeries, hSeries, lSeries, cSeries, volumeSeries]
  }
  // else if(['arearange', 'candlestick', 'ohlc', 'hlc', 'hollowcandlestick'].includes(chartType)){
  //   return [stockSeries, volumeSeries, ...seriesList]
  // }
  return [stockSeries, volumeSeries, ...seriesList]
}
const useYAxis = ({ yPlotBands, yPlotLines, settings }) => {
  const yAxis = [{
    ...settings,
    labels: {
      format: `${settings.input}`,
      style: settings.style,
      align: 'right',
      x: -3
    },
    title: {
      text: 'Stock'
    },
    height: '60%',
    lineWidth: 2,
    resize: {
      enabled: true
    },
    crosshair: true,
    plotBands: [...yPlotBands],
    plotLines: [...yPlotLines],
    endOnTick: false
  }]
  // if(chartType === 'bellcurve'){
  //     yAxis.push({
  //         title: { text: 'Bell curve' },
  //         opposite: true
  //     })
  // }else{
  // }
  yAxis.push({
    labels: {
      align: 'right',
      x: -3
    },
    title: {
      text: 'Volume'
    },
    top: '65%',
    height: '35%',
    offset: 0,
    lineWidth: 2
  })
  return yAxis
}
const useXAxis = ({ xPlotBands, xPlotLines, settings }) => {
  const xAxis = [{
    // type: 'datetime',
    ...settings,
    crosshair: true,
    labels: {
      format: `${settings.input}`,
      style: settings.style
    },
    plotBands: [...xPlotBands],
    plotLines: [...xPlotLines]
  }]

  // if(chartType === 'bellcurve'){
  //     xAxis.push({
  //         title: {
  //             text: 'Bell Curve'
  //         },
  //         alignTicks: false,
  //         opposite: true
  //     })
  // }
  return xAxis
}
const usePlotOptions = ({ config, dataLabelsData }) => {
  let {
    pointDataLabelEnabled
  } = config
  // let stacking, columnDataLabelEnabled = false
  // if (['stacked-bar', 'columnpyramid-stack'].indexOf(chartType) > -1) {
  //   stacking = 'normal'
  //   // columnDataLabelEnabled = true
  // } else if (['percent-area'].indexOf(chartType) > -1) {
  //   stacking = 'percent'
  // }
  return {
    series: {
      showInNavigator: true,
      gapSize: 6,
      allowPointSelect: true,
      // compare: 'value',
      // cumulative: true,
      enabled: pointDataLabelEnabled,
      dataLabels: dataLabelsData?.all || {}
    },
    flags: {
      color: Highcharts.getOptions().colors[0], // same as onSeries
      fillColor: Highcharts.getOptions().colors[0],
      // width: 16,
      style: { // text style
        color: 'white'
      },
      states: {
        hover: {
          fillColor: '#395C84' // darker
        }
      },
      textAlign: 'center'
    },
    line: {
    },
    column: {
      // stacking: stacking,
      dataLabels: {
        // enabled: columnDataLabelEnabled
      },
      // depth: 25
    },
    columnpyramid: {
      // stacking: stacking,
      dataLabels: {
        // enabled: columnDataLabelEnabled
      }
    },
    area: {
      // stacking: stacking,
      lineColor: '#666666',
      lineWidth: 1,
      marker: {
        lineWidth: 1,
        lineColor: '#666666'
      }
    },
    scatter: {
      marker: {
        radius: 5,
        states: {
          hover: {
            enabled: true,
            lineColor: 'rgb(100,100,100)'
          }
        }
      },
      states: {
        hover: {
          marker: {
            enabled: false
          }
        }
      },
      tooltip: {
        headerFormat: '<b>{series.name}</b><br>',
        pointFormat: '{point.x} cm, {point.y} kg'
      }
    }
  }
}

const pathPoints = "M-4.5,0.5 L3.5,0.5 L3.5,15.5 L-4.5,15.5 L-4.5,0.5 M-1.5,4 L-1.5,12 M0.5,4 L0.5,12";
const pathAttribute = {
  d: pathPoints,
  stroke: '#999999',
  'stroke-width': 1,
  fill: '#f2f2f2',
  width: 16,
  height: 16,
  cursor: 'col-resize'
}

function ChartContainer(props) {
  const chartComponent = useRef(null);
  const dispatch = useDispatch()
  const { data: initialData, yAxisFields } = props
  const highChartData = useSelector(state => state.highChartData) || {}
  const chartSettings = useSelector(state => state.productData.chartSettings)
  const tooltipFormatData = useSelector(state => state.productData.tooltipFormatData)
  const dataLabelsData = useSelector(state => state.productData.dataLabelsData)
  const annotations = useSelector(state => state.productData.annotations)
  const plotHighlights = useSelector(state => state.productData.plotHighlights)
  const activeChartAnchor = useSelector(state => state.productData.activeChartAnchor)


  const dragging = useRef(null)
  const draggingAxis = useRef(null)
  const draggingId = useRef(null)
  const clickX = useRef(null)
  const clickY = useRef(null)
  const translateX = useRef(0)
  const translateY = useRef(0)
  const plotBandBar = useRef(null)
  const plotResizeHandlerRef = useRef(null)
  const plotBandAnchor = useRef('move')
  const plotBandResizeHandler = useRef(null)

  console.log(plotHighlights, 'plotHighlights')
  const {
    // plotBands, plotLines, theme
  } = highChartData

  const onAddAnnotation = (event) => {
    dispatch(setProductOpt({ path: 'activeChartAnchor', value: null }))
    if(activeChartAnchor === 'annotation'){
      dispatch(addAnnotation({
        point: {
          xAxis: 0,
          yAxis: 0,
          x: event.xAxis[0].value,
          y: event.yAxis[0].value
        },
        isFresh: true,
        text: 'Insert Text'
      }))
    }
  }

  const onMouseDown = (e, that, item, axis) => {
    if(!activeChartAnchor){
      dragging.current = that;
      draggingAxis.current = axis
      draggingId.current = item.id
      clickX.current = e.layerX;
      clickY.current = e.layerY;
      plotBandAnchor.current = 'move'

      if (typeof that.svgElem?.translateX == 'number')
        translateX.current = that?.svgElement?.translateX;

      if (typeof that.svgElem?.translateY == 'number')
        translateY.current = that?.svgElement?.translateY || 0;
    }
  }

  const xPlotBands = useMemo(() => {
    return plotHighlights.filter(item => item.axis === 'x' && item.type === 'band').map(item => {
      return {
        ...item,
        from: new Date(item.fromX),
        to: new Date(item.toX),
        zIndex: 1,
        events: {
          mousedown: function(e){
            onMouseDown(e, this, item, 'x')
          },
          click: function(e) {
            // onMouseDown(e, this, item, 'x')
            onAddAnnotation(e)
          }
        }
      }
    })
  }, [
    plotHighlights
  ])
  const yPlotBands = useMemo(() => {
    return plotHighlights.filter(item => item.axis === 'y' && item.type === 'band').map(item => {
      return {
        ...item,
        from: item.fromY,
        to: item.toY,
        zIndex: 1,
        events: {
          mousedown: function(e){
            onMouseDown(e, this, item, 'y')
          },
          click: function(e) {
            // onMouseDown(e, this, item, 'y')
            onAddAnnotation(e)
          }
        }
      }
    })
  }, [
    plotHighlights
  ])
  const xPlotLines = useMemo(() => {
    return plotHighlights.filter(item => item.axis === 'x' && item.type === 'line').map(item => {
      return {
        ...item,
        width: 2,
        value: item.fromX,
        zIndex: 3,
        events: {
          mousedown: function(e){
            onMouseDown(e, this, item, 'x')
          },
        }
      }
    })
  }, [
    plotHighlights
  ])
  const yPlotLines = useMemo(() => {
    return plotHighlights.filter(item => item.axis === 'y' && item.type === 'line').map(item => {
      return {
        ...item,
        width: 2,
        value: item.fromY,
        zIndex: 3,
        events: {
          mousedown: function(e){
            onMouseDown(e, this, item, 'y')
          },
        }
      }
    })
  }, [
    plotHighlights
  ])

  const { data, volume } = useData(initialData, props.xAxis, yAxisFields)
  const series = useSeries({
    config: highChartData,
    data,
    volume,
    tooltip: tooltipFormatData,
    dataLabels: dataLabelsData
  })
  const yAxis = useYAxis({ yPlotBands, yPlotLines, settings: chartSettings?.axes?.vertical })
  const xAxis = useXAxis({ xPlotBands, xPlotLines, settings: chartSettings?.axes?.horizontal })
  const plotOptions = usePlotOptions({ config: highChartData, dataLabelsData })

  // const options = { style: 'currency', currency: 'USD' };
  // const numberFormat = new Intl.NumberFormat('en-US', options);
  // console.log(chartSettings, 'chartSettings')
  // console.log(initialData, 'initialData')
  // console.log(xAxis, 'chartSettings')
  const resizePath = (currentD, increasingWidth, direction) => {
    const moves = currentD.split('M ').filter(item => item !== '')
    let newD = ''
    for(let m of moves){
      const points = m.split(' ');
      let x1 = parseFloat(points[0]);
      let y1 = parseFloat(points[1]);
      let x2 = parseFloat(points[3]);
      let y2 = parseFloat(points[4]);
      let x3 = parseFloat(points[6]);
      let y3 = parseFloat(points[7]);
      let x4 = parseFloat(points[9]);
      let y4 = parseFloat(points[10]);
      if(direction === 'resize-from-left'){
        x1 += increasingWidth;
        x2 += increasingWidth;
      }else if(direction === 'resize-from-right'){
        x3 += increasingWidth;
        x4 += increasingWidth;
      }else if(direction === 'resize-from-top'){
        y1 += increasingWidth;
        y2 += increasingWidth;
      }else if(direction === 'resize-from-bottom'){
        y3 += increasingWidth;
        y4 += increasingWidth;
      }
      newD += 'M ' + x1 + ' ' + y1 + ' L ' + x2 + ' ' + y2 + ' L ' + x3 + ' ' + y3 + ' L ' + x4 + ' ' + y4 + ' Z '
    }

    return newD;
  }

  const configStock = useMemo(() => ({
    ...chartSettings,
    chart: {
      ...chartSettings.chart,
      height: 600,
      alignTicks: true,
      zoomType: 'x',
      // options3d: {
      //     enabled: true,
      //     alpha: 15,
      //     beta: 15,
      //     depth: 50,
      //     viewDistance: 25
      // }
      events: {
        // addSeries: function(event) {
        //     // Function which saves the new background color.
        //     console.log(event, 'event')
        //     console.log(this.chart, 'this.chart')
        //     if(event.options.type === 'flags'){
        //         event.preventDefault()
        //         // console.log(this.chart, 'this.chart.series')
        //         event.target.options.series.push(event.options.data[0])
        //         // event.target.series.redraw()
        //     }
        // }
        render: function (event) {
          const chart = this;
          const innerXPlotBands = chart.xAxis[0].plotLinesAndBands;
          const innerYPlotBands = chart.yAxis[0].plotLinesAndBands;

          if(plotResizeHandlerRef?.current?.destroy){
            plotResizeHandlerRef.current.destroy()
          }
          plotResizeHandlerRef.current = chart.renderer.g().attr({
            'data-z-index': 1
          }).add();
          innerXPlotBands.forEach((plotBand) => {
            if(plotBand.options.type === 'band'){
              const bbox = plotBand.svgElem.getBBox();
              const leftMiddle = bbox.x;
              const rightMiddle = bbox.x + bbox.width;
              const yPosition = bbox.y + bbox.height / 2 - 8
              const leftIcon = chart.renderer.path()
                .attr({
                  ...pathAttribute,
                  'data-id': plotBand.id,
                  zIndex: 3,
                  transform: `translate(${leftMiddle}, ${yPosition})`,
                }).add(plotResizeHandlerRef.current).toFront()

              const rightIcon = chart.renderer.path()
                .attr({
                  ...pathAttribute,
                  'data-id': plotBand.id,
                  zIndex: 3,
                  transform: `translate(${rightMiddle}, ${yPosition})`,
                }).add(plotResizeHandlerRef.current).toFront()

              leftIcon.on('mousedown', function (e) {
                dragging.current = plotBand
                draggingAxis.current = 'x'
                plotBandAnchor.current = 'resize-from-left'
                draggingId.current = plotBand.id
                clickX.current = e.layerX;
                clickY.current = e.layerY;

                plotBandResizeHandler.current = this
              });
              rightIcon.on('mousedown', function (e) {
                dragging.current = plotBand
                draggingAxis.current = 'x'
                plotBandAnchor.current = 'resize-from-right'
                draggingId.current = plotBand.id
                clickX.current = e.layerX;
                clickY.current = e.layerY;

                plotBandResizeHandler.current = this
              });
            }
          })
          innerYPlotBands.forEach((plotBand) => {
            if(plotBand.options.type === 'band'){
              const bbox = plotBand.svgElem.getBBox();
              const topMiddle = bbox.y;
              const bottomMiddle = bbox.y + bbox.height;
              const xPosition = bbox.x + bbox.width / 2 - 8
              const topIcon = chart.renderer.path()
                .attr({
                  ...pathAttribute,
                  'data-id': plotBand.id,
                  cursor: 'row-resize',
                  zIndex: 3,
                  transform: `translate(${xPosition}, ${topMiddle}) rotate(90)`
                }).add(plotResizeHandlerRef.current).toFront()

              const bottomIcon = chart.renderer.path()
                .attr({
                  ...pathAttribute,
                  'data-id': plotBand.id,
                  cursor: 'row-resize',
                  zIndex: 3,
                  transform: `translate(${xPosition}, ${bottomMiddle}) rotate(90)`,
                }).add(plotResizeHandlerRef.current).toFront()

              topIcon.on('mousedown', function (e) {
                dragging.current = plotBand
                plotBandAnchor.current = 'resize-from-top'
                draggingId.current = plotBand.id
                clickX.current = e.layerX;
                clickY.current = e.layerY;
                draggingAxis.current = 'y'

                plotBandResizeHandler.current = this
              });
              bottomIcon.on('mousedown', function (e) {
                dragging.current = plotBand
                plotBandAnchor.current = 'resize-from-bottom'
                draggingId.current = plotBand.id
                clickX.current = e.layerX;
                clickY.current = e.layerY;
                draggingAxis.current = 'y'

                plotBandResizeHandler.current = this
              });
            }
          })
        },
        load: function(event) {
          const chart = this;

          this.container.onmousemove = (e) => {
            if (dragging?.current?.svgElem) {
              const offset = chart.xAxis[0].translate(clickX.current - chart.plotLeft, true)
              const bBox = dragging.current.svgElem.getBBox()
              if(draggingAxis.current === 'x'){
                if (plotBandAnchor.current === 'move') {
                  const x = e.layerX - clickX.current + translateX.current;
                  if (dragging.current.svgElem.translate) {
                    dragging.current.svgElem.translate(x, 0);
                  }
                  /// Moving Resize Handlers corresponding Plot Band movement
                  const resizeHandlers = document.querySelectorAll(`[data-id='${draggingId.current}']`)
                  const leftHandlerX = bBox.x + x
                  const rightHandlerX = leftHandlerX + bBox.width
                  const yPosition = bBox.y + bBox.height / 2 - 8
                  if(resizeHandlers[0]){
                    resizeHandlers[0].setAttribute('transform', `translate(${leftHandlerX}, ${yPosition})`)
                  }
                  if(resizeHandlers[1]){
                    resizeHandlers[1].setAttribute('transform', `translate(${rightHandlerX}, ${yPosition})`)
                  }
                }else if(['resize-from-left', 'resize-from-right'].includes(plotBandAnchor.current)){
                  const {
                    width: currentWidth,
                    x: translateX,
                    y: translateY,
                    height: currentHeight
                  } = dragging.current.svgElem.getBBox();
                  let x
                  if(plotBandAnchor.current === 'resize-from-left') {
                    x = e.layerX;
                  }else {
                    x = e.layerX - currentWidth;
                  }

                  const currentD = dragging.current.svgElem['d']
                  const newBandD = resizePath(currentD, (x - translateX), plotBandAnchor.current)

                  dragging.current.svgElem.attr({
                    d: newBandD
                  })
                  // Update the X-Position of Resize Handler button
                  const yPosition = translateY + currentHeight / 2 - 8
                  plotBandResizeHandler.current.setAttribute('transform', `translate(${e.layerX}, ${yPosition})`)
                }
              }else{
                if(plotBandAnchor.current === 'move') {
                  const y = e.layerY - clickY.current + translateY.current;
                  if (dragging.current.svgElem.translate) {
                    dragging.current.svgElem.translate(0, y);
                  }
                  /// Moving Resize Handlers corresponding Plot Band movement
                  const resizeHandlers = document.querySelectorAll(`[data-id='${draggingId.current}']`)
                  const leftHandlerY = bBox.y + y
                  const rightHandlerY = leftHandlerY + bBox.height
                  const xPosition = bBox.x + bBox.width / 2 - 8
                  if(resizeHandlers[0]){
                    resizeHandlers[0].setAttribute('transform', `translate(${xPosition}, ${leftHandlerY}), rotate(90)`)
                  }
                  if(resizeHandlers[1]){
                    resizeHandlers[1].setAttribute('transform', `translate(${xPosition}, ${rightHandlerY}), rotate(90)`)
                  }
                }else if(['resize-from-top', 'resize-from-bottom'].includes(plotBandAnchor.current)){
                  const {
                    width: currentWidth,
                    x: innerTranslateX,
                    y: innerTranslateY,
                    height: currentHeight
                  } = dragging.current.svgElem.getBBox();
                  let y
                  if(plotBandAnchor.current === 'resize-from-top') {
                    y = e.layerY;
                  }else {
                    y = e.layerY - currentHeight;
                  }

                  const currentD = dragging.current.svgElem['d']
                  const newBandD = resizePath(currentD, (y - innerTranslateY), plotBandAnchor.current)
                  dragging.current.svgElem.attr({
                    d: newBandD
                  })

                  // Update the X-Position of Resize Handler button
                  const xPosition = innerTranslateX + currentWidth / 2 - 8
                  plotBandResizeHandler.current.setAttribute('transform', `translate(${xPosition}, ${e.layerY}), rotate(90)`)
                }
              }
            }
          }
          this.container.onmouseup = (e) => {
            let currentDraggingIdx = -1
            if(draggingAxis.current === 'x'){
              currentDraggingIdx = chart.xAxis[0].plotLinesAndBands.findIndex(item => item.id === draggingId.current)
            }else {
              currentDraggingIdx = chart.yAxis[0].plotLinesAndBands.findIndex(item => item.id === draggingId.current)
            }
            if(currentDraggingIdx > -1){
              if(draggingAxis.current === 'x'){
                const {
                  x,
                  y,
                  width,
                  height
                } = chart.xAxis[0].plotLinesAndBands[currentDraggingIdx].svgElem.element.getBoundingClientRect();
                const {
                  x: chartX,
                  y: chartY
                } = chart.container.getBoundingClientRect();

                // console.log(chart.xAxis[0], 'chart.xAxis[0]')
                const plotBandX = x - chartX,
                  plotBandX2 = x - chartX + width,
                  x1Date = new Date(chart.xAxis[0].toValue(plotBandX)),
                  x2Date = new Date(chart.xAxis[0].toValue(plotBandX2));
                // console.log(x1Date, 'x1Date')
                // console.log(x2Date, 'x2Date')

                // console.log(plotBandAnchor.current, draggingId.current, 'plotBandAnchor.current')
                if(['move', 'resize-from-right'].includes(plotBandAnchor.current)){
                  dispatch(updatePlotHighlights({
                    id: draggingId.current, path: 'toX', newValue: x2Date
                  }))
                }
                if(['move', 'resize-from-left'].includes(plotBandAnchor.current)){
                  dispatch(updatePlotHighlights({
                    id: draggingId.current, path: 'fromX', newValue: x1Date
                  }))
                }
              }else{
                const {
                  x,
                  y,
                  width,
                  height
                } = chart.yAxis[0].plotLinesAndBands[currentDraggingIdx].svgElem.element.getBoundingClientRect();
                const {
                  x: chartX,
                  y: chartY
                } = chart.container.getBoundingClientRect();
                // console.log(chart.xAxis[0], 'chart.xAxis[0]')
                const plotBandY = y - chartY,
                  plotBandY2 = y - chartY + height,
                  y1Val = chart.yAxis[0].toValue(plotBandY),
                  y2Val = chart.yAxis[0].toValue(plotBandY2);
                // console.log(x1Date, 'x1Date')
                // console.log(x2Date, 'x2Date')

                // console.log(plotBandAnchor.current, draggingId.current, 'plotBandAnchor.current')
                if(['move', 'resize-from-top'].includes(plotBandAnchor.current)){
                  dispatch(updatePlotHighlights({
                    id: draggingId.current, path: 'fromY', newValue: y1Val < 1 ? 0 : y1Val
                  }))
                }
                if(['move', 'resize-from-bottom'].includes(plotBandAnchor.current)){
                  dispatch(updatePlotHighlights({
                    id: draggingId.current, path: 'toY', newValue: y2Val < 1 ? 0 : y2Val
                  }))
                }
              }

              // alert("From: " + x1Date + ', To: ' + x2Date)
              dragging.current = null;
              draggingAxis.current = null
              plotBandAnchor.current = 'move'
              clickX.current = null;
              clickY.current = null;
              draggingId.current = null;
              translateX.current = 0;
              translateY.current = 0;
            }
          }

          // Highcharts.addEvent(chart.container, 'mousemove', function (e) {
          //   const chartOffset = Highcharts.offset(chart.container);
          //   const xPosition = e.pageX - chartOffset.left;
          //   const yPosition = e.pageY - chartOffset.top;
          //
          //   // Get x-axis value based on cursor position
          //   const xAxis = chart.xAxis[0];
          //   const xValue = xAxis.toValue(xPosition);
          //
          //   // Find the nearest points at the given x value
          //   const pointsToShow = chart.series
          //     .map(series => series.searchPoint({ x: xValue, chartX: xPosition, chartY: yPosition }, true))
          //     .filter(Boolean); // Remove null/undefined points
          //
          //   // Use Highcharts' tooltip functionality to refresh/hide the tooltip
          //   if (pointsToShow.length > 0) {
          //     chart.tooltip.refresh(pointsToShow, e);
          //   } else {
          //     chart.tooltip.hide();
          //   }
          // });
          //
          // // Hide the tooltip when the mouse leaves the chart
          // Highcharts.addEvent(chart.container, 'mouseleave', function () {
          //   chart.tooltip.hide();
          // });
        },
        onmousemove: function(event){
          // console.log(event.srcElement, 'mousemove')
        },
        click: function (event) {
          // console.log(event, 'event.xAxis')
          onAddAnnotation(event)
        },
        selection: function (event) {
          var extremesObject = event.xAxis[0],
            min = extremesObject.min,
            max = extremesObject.max,
            xAxis = this.xAxis[0];
          var barEnd = xAxis.translate(max) + this.plotLeft;
          var barStart = xAxis.translate(min) + this.plotLeft;
          var barTop = this.plotTop;
          var barHeight = barTop / 2;
          var barLength = barEnd - barStart;
          if (plotBandBar.current.destroy) {
            plotBandBar.current.destroy();
          }
          plotBandBar.current = this.renderer.rect(barStart, barTop, barLength, barHeight, 0)
            .attr({
              fill: '#000000',
              zIndex: 3
            });
          plotBandBar.add();
        }
      }
    },
    exporting: {
      buttons: {
        enabled: false
      }
    },
    data: {
      seriesMapping: initialData
    },
    annotations: [{
      labels: annotations
    }],
    stockTools: {
      gui: {
        enabled: false,
        buttons: [
          // 'typeChange',
          // 'separator',

          // 'thresholds',
          // 'separator',
          // 'indicators',
          // 'separator',
          // 'simpleShapes',
          // 'lines',
          // 'crookedLines',
          // 'measure',
          // 'advanced',
          // 'toggleAnnotations',
          // 'separator',
          // 'verticalLabels',
          // 'flags',
          // 'separator',
          // 'zoomChange',
          // 'fullScreen',
          // 'separator',
          // 'currentPriceIndicator',
          // 'saveChart'
        ],
        definitions: {
          thresholds: {
            className: 'highcharts-threshold-annotation',
            symbol: 'horizontal-line.svg'
          },
          typeChange: {
            items: ['typeOHLC', 'typeLine']
          },
          flags: {
            items: ['flagCirclepin', 'flagDiamondpin', 'flagSquarepin', 'flagSimplepin']
          }
        }
      }
    },
    navigation: {
      bindings: {
        thresholds: {
          className: 'highcharts-threshold-annotation',
          start: function (event) {
            var chart = this.chart,
              x = chart.xAxis[0].toValue(event.chartX),
              y = chart.yAxis[0].toValue(event.chartY),
              colors = chart.options.colors,
              series = chart.series[0],
              zones = series.userOptions.zones || [];

            chart.customColorIndex = chart.customColorIndex || 1;

            chart.customColorIndex++;

            if (
              chart.customColorIndex === colors.length
            ) {
              chart.customColorIndex = 1;
            }

            zones.push({
              color: colors[chart.customColorIndex],
              value: y
            });

            chart.addAnnotation({
              langKey: 'thresholds',
              zoneIndex: zones.length - 1,
              type: 'infinityLine',
              draggable: 'y',
              events: {
                drag: function (e) {
                  var newZones = series.userOptions.zones;

                  newZones[this.userOptions.zoneIndex].value =
                    chart.yAxis[0].toValue(e.chartY);

                  chart.series[0].update({
                    zones: newZones
                  });
                }
              },
              typeOptions: {
                type: 'horizontalLine',
                points: [{
                  x: x,
                  y: y
                }]
              }
            });

            chart.series[0].update({
              zones: zones
            });
          }
        }
      }
    },
    yAxis: yAxis,
    navigator: {
      series: {
        color: 'red'
      }
    },
    tooltip: {
      ...chartSettings.tooltip,
      shared: true,
      // pointFormat: `${chartSettings.tooltip.pointFormatHeader}: <br /> ${chartSettings.tooltip.pointFormatBody}`,
      // formatter: function () {
      //   console.log(this, 'this')
      //   return `
      //     {point.key}
      //   `
      //   // return this.y + '</b><br/>' + moment(this.x).format('MMMM Do YYYY, h:mm')
      //   // return this.y + '</b><br/>' + moment(this.x).format('MMMM Do YYYY, h:mm')
      // },
      crosshairs: true,
      split: true,
    },
    plotOptions: plotOptions,
    credits: {
      enabled: false
    },

    xAxis: xAxis,
    rangeSelector: {
      enabled: false
    },
    series: series,
    colors: chartSettings?.colors || [],
    responsive: {
      rules: [{
        condition: {
          minWidth: 600
        },
        chartOptions: {
          yAxis: [{
            labels: {
              align: 'left'
            }
          }, {
            labels: {
              align: 'left'
            }
          }]
        }
      }]
    }
  }), [
    activeChartAnchor,
    annotations,
    series,
    plotOptions,
    xAxis,
    yAxis,
    chartSettings,
    initialData,
    dragging,
    draggingId
  ])
  console.log(configStock, 'configStock')


  if (data['default']?.type === 'depth-chart') {
    return <div>DepthChart</div>
  }

  return (
    <>
      <StockHighChart
        options={configStock}
      />
    </>
  )
}

export default ChartContainer