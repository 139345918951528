// API BASE URLS ------------------------------------------------------------------- //
//const HOST = process.env.NODE_ENV === 'development' ? '' : 'http://localhost:5000';
const HOST = '';
const API = HOST + '/api/';
const isLocalhost = window.location.host.startsWith('localhost');
const DOCUMENT_HOST = isLocalhost ? 'http://localhost:8888/' : window.location.origin + '/';
const DOCUMENT_API = DOCUMENT_HOST + 'api/';

let PY_API, QA_API, QA_CLUSTER
if (process.env.NODE_ENV === 'development') {
    PY_API = 'http://localhost:8080'
    QA_API = 'http://localhost:8081/'
    QA_CLUSTER = 'local';
} else {
    console.log('Prod')
    PY_API = ''
    QA_API = process.env.REACT_APP_IS_DEV === 'True' ?
        'https://plotter-frontend-qa-runtime-service-ycyfoaj6aa-uc.a.run.app/' :
        'https://plotter-frontend-qa-runtime-service-7m5r3wt7kq-uc.a.run.app/'
    QA_CLUSTER = 'server'
}

// QA_API = process.env.REACT_APP_IS_DEV === 'True' ?
//     'https://plotter-frontend-qa-runtime-service-ycyfoaj6aa-uc.a.run.app/' :
//     'https://plotter-frontend-qa-runtime-service-7m5r3wt7kq-uc.a.run.app/'
// QA_CLUSTER = 'server'
export const PY_USER_API = PY_API + '/user_api'


// AUTHENTICATION ------------------------------------------------------------------ //
const AUTHENTICATION = API + 'Authentication/';
export const LOGIN = AUTHENTICATION + 'Login';
export const LOGOUT = AUTHENTICATION + 'Logout';
export const REFRESH_TOKEN = AUTHENTICATION + 'Refresh';
export const SET_LANGUAGE = AUTHENTICATION + 'SetLanguage';
export const EMP_LOGGED = '/user'

// User
export const USER_FAVORITES = PY_USER_API + `/favorites`;
export const USER_FAVORITE_GROUP = (group_id) => PY_USER_API + `/favorites/${group_id}`
export const USER_FAVORITE_CONFIG = (group_id) => PY_USER_API + `/favorites_config/${group_id}`

// EOD Data----------------------------------------------------------------------- //
export const EOD_FETCH_FEED_SOURCES = PY_USER_API + '/fetch_feed_sources';
export const EOD_FETCH_FEED_GROUPS = DOCUMENT_API + 'eod/fetch_feed_groups';
export const EOD_FETCH_FEED_SECTIONS = DOCUMENT_API + 'eod/fetch_feed_sections';
export const EOD_FETCH_STOCK_PRODUCTS = DOCUMENT_API + 'eod/fetch_stock_products';

export const EOD_FETCH_PRODUCT_SQL = DOCUMENT_API + 'eod/fetch_stock_product_sql';
export const EOD_FETCH_BG_STOCK_PRODUCT = DOCUMENT_API + 'eod/fetch_bg_stock_product';
export const EOD_FETCH_CS_DATA_FOR_TRANSFORM = DOCUMENT_API + 'eod/fetch_cs_data_for_transform';
export const EOD_FETCH_BQ_DATA_FOR_TRANSFORM = DOCUMENT_API + 'eod/fetch_bq_data_for_transform';
export const EOD_FETCH_BEA_MNE_INVESTMENTS = DOCUMENT_API + 'eod/fetch_bea_mne_investments'
export const EOD_FETCH_BEA_MNE_DIRECTIONS = DOCUMENT_API + 'eod/fetch_bea_mne_directions'
export const EOD_FETCH_BEA_MNE_SERIES = DOCUMENT_API + 'eod/fetch_bea_mne_series'
export const EOD_FETCH_BEA_MNE_CLASSIFICATIONS = DOCUMENT_API + 'eod/fetch_bea_mne_classifications'
export const EOD_FETCH_BEA_MNE_PRODUCT_DATA = DOCUMENT_API + 'eod/fetch_bea_mne_product_data'
export const EOD_SAVE_CHART = DOCUMENT_API + 'eod/save_chart'
export const EOD_FETCH_PRODUCT_DATA = PY_USER_API + '/stock_values';
export const EOD_FETCH_PRODUCT_NEWS = PY_USER_API + '/news'

export const EOD_FETCH_SUMMARY_DATASET = PY_USER_API + '/summary/dataset'
export const EOD_FETCH_SUMMARY_DATA_TABLE = PY_USER_API + '/summary/data_table'

export const EOD_FETCH_DATATABLE_FILTERS = DOCUMENT_API + 'eod/datatable_filters/fetch'
export const EOD_SAVE_DATATABLE_FILTERS = DOCUMENT_API + 'eod/datatable_filters/save'
export const EOD_DELETE_DATATABLE_FILTERS = DOCUMENT_API + 'eod/datatable_filters/delete'

export const EOD_SEARCH_SUGGESTIONS = DOCUMENT_API + 'eod/search_suggestions'
export const EOD_SEARCH = DOCUMENT_API + 'eod/search'

export const EOD_GET_QA_DATA = DOCUMENT_API + 'eod/get_qa_data'
export const EOD_SAVE_QA_REPORT = DOCUMENT_API + 'eod/save_qa_report'
export const EOD_GET_QA_REPORT_DATA = DOCUMENT_API + 'eod/get_qa_report_data'

export const EOD_GET_QA_RANGES = DOCUMENT_API + 'eod/get_qa_ranges'
export const EOD_SAVE_QA_RANGE = DOCUMENT_API + 'eod/save_qa_range'
export const EOD_SAVE_INDIVIDUAL_QA_REPORT = DOCUMENT_API + 'eod/save_individual_qa_report'
export const EOD_RETRY_QA_RANGE = DOCUMENT_API + 'eod/retry_qa_range'
export const EOD_INIT_QA_RANGE = DOCUMENT_API + 'eod/init_qa_range'
export const EOD_SAVE_QA_RESULT = DOCUMENT_API + 'eod/save_qa_result'
export const EOD_GET_QA_RESULT = DOCUMENT_API + 'eod/get_qa_results'
export const EOD_DELETE_QA_REPORT = DOCUMENT_API + 'eod/delete_qa_report'

export const EOD_FETCH_FRONTEND_QA_REPORTS = DOCUMENT_API + 'eod/fetch_frontend_qa_reports'
export const EOD_FETCH_FRONTEND_QA_PRODUCTS = DOCUMENT_API + 'eod/fetch_frontend_qa_products'
export const EOD_FETCH_FRONTEND_QA_RESULTS = DOCUMENT_API + 'eod/fetch_frontend_qa_results'
export const EOD_SEARCH_FRONTEND_QA_REPORT = DOCUMENT_API + 'eod/search_frontend_qa_report'
export const EOD_SEARCH_QA_DATA = DOCUMENT_API + 'eod/search_qa_data'
export const EOD_DELETE_FRONTEND_QA_REPORT = DOCUMENT_API + 'eod/delete_frontend_qa_report'
export const EOD_UPDATE_FRONTEND_QA_BATCH_GROUP = DOCUMENT_API + 'eod/update_batch_group'
export const EOD_DELETE_FRONTEND_QA_BATCH_GROUP = DOCUMENT_API + 'eod/delete_batch_group'
export const EOD_UPDATE_FRONTEND_QA_BATCH = DOCUMENT_API + 'eod/update_batch'
export const EOD_CHECK_FRONTEND_QA_REPORT = DOCUMENT_API + 'eod/check_report'
export const EOD_CHECK_FRONTEND_QA_BATCH = DOCUMENT_API + 'eod/check_batch'
export const EOD_SKIP_FRONTEND_QA_BATCH = DOCUMENT_API + 'eod/skip_batch'
export const EOD_GET_DATABASE_CODES_QA = DOCUMENT_API + 'eod/get_database_codes_qa'
export const EOD_GET_FEED_SOURCE_DATABASE_CODES_QA = DOCUMENT_API + 'eod/get_feed_source_database_codes_qa'
export const START_FRONTEND_PROUDCT_RUN_QA = QA_API + 'run-qa'
export const START_FRONTEND_QA_REPORT = QA_API + 'run-qa-report'
export const TEST_TRIGGER = DOCUMENT_API + 'eod/test_trigger'