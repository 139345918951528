import React, { ReactNode } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import DoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import DoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {CircularProgress} from "@mui/material";
import TimeFramesBox from "./TimeFramesBox";

const drawerWidth = 340;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const LayoutHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  padding: '10px',
  justifyContent: 'space-between',
}));


interface  ChildrenProp {
  children?: ReactNode;
  loading?: boolean,
  open?: boolean,
  onSidebarChange? : (val: boolean) => void
}
export const DrawerHeader: React.FC<ChildrenProp> = ({ children }) => <>{children}</>;
export const DrawerBody: React.FC<ChildrenProp> = ({ children }) => <>{children}</>;
export const DrawLayoutBody: React.FC<ChildrenProp> = ({ children }) => <>{children}</>;


export const  DrawerLayout: React.FC<ChildrenProp> = ({
  loading,
  children,
  open = false,
  onSidebarChange
}) =>  {
  const theme = useTheme();

  let drawerHeaderNode: ReactNode | undefined;
  let drawerBodyNode: ReactNode | undefined;
  let layoutBodyNode: ReactNode | undefined;

  // Iterate over the children to identify header and body components
  React.Children.forEach(children, child => {
    if (React.isValidElement(child)) {
      if (child.type === DrawerHeader) {
        drawerHeaderNode = child;
      } else if (child.type === DrawerBody) {
        drawerBodyNode = child;
      } else if (child.type === DrawLayoutBody) {
        layoutBodyNode = child;
      }
    }
  });

  const handleDrawerOpen = () => {
    if(onSidebarChange){
      onSidebarChange(true)
    }
  };

  const handleDrawerClose = () => {
    if(onSidebarChange){
      onSidebarChange(false)
    }
  };

  return (
    <Box sx={{ display: 'flex', position: 'relative', width: '100%', height: '100%' }}>
      <CssBaseline />
      <Box sx={{ position: 'absolute', left: '31px', top: '6px', zIndex: 1000 }}>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
        >
          <DoubleArrowRightIcon />
        </IconButton>
      </Box>
      <Divider />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            // position: 'absolute',
            position: 'unset'
          },
          display: 'flex',
          heigh: '100%',
          flexFlow: 'column',
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <LayoutHeader>
          { drawerHeaderNode}
          <Box sx={{ height: '100%' }}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <DoubleArrowLeftIcon /> : <DoubleArrowRightIcon />}
            </IconButton>
          </Box>
        </LayoutHeader>
        <Divider />
        <div style={{ flex: 1, overflow: 'auto' }}>
          { drawerBodyNode }
        </div>
      </Drawer>
      <Main open={open} style={{ padding: 0, overflow: 'auto', height: '100%' }}>
        {
          loading ? (
            <CircularProgress sx={{ margin: '100px auto', display: 'block' }} color="secondary"/>
          ) : (
            <>
              <Box sx={{ height: 'calc(100% - 111px)'}}>
                {
                  layoutBodyNode
                }
              </Box>
              <TimeFramesBox />
            </>
          )
        }
      </Main>
    </Box>
  );
}
