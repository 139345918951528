import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useParams, useHistory } from 'react-router-dom'
import {
    Box
} from "@mui/material"

import ListItem from './Details/ListItem';

import { fetchStockProducts, clearStockProducts } from "store/slices/EodDataSlice";
import { fetchSearchData } from 'store/slices/SearchSlice';

// const productPath = '/product/detail'
const productPath = '/product'

const ListItemComponent = ({feedSource, item, active, clickHandler, setSelected, setHovered }) => (
    <Box
        className={`item ${active ? "active" : ""}`}
        onClick={() => clickHandler(item)}
        onMouseEnter={() => setSelected(item)}
        onMouseLeave={() => setHovered(undefined)}
    >
        <ListItem
            selected={active}
            style={{  }}
        >
            <span style={{ minWidth: '62px', display: 'block', lineHeight: '20px' }}>
            {
                item.product_code
            }
            </span>
            <span title={item.title ? ` ${item?.title?.replace('EOD/', '')}` : ''} style={{ fontSize: '12px', display: 'block',  whiteSpace: 'nowrap',  overflow: 'hidden', textOverflow: 'ellipsis', width: '100%'}}>
            {
                item.title ? ` ${item?.title?.replace('EOD/', '')}` : ''
            }
            </span>
            {/* {
                ['Barchart', 'ALPACA'].indexOf(feedSource) > -1  ? item.product_code : (item.title ? item.title : item.product_code).replace('EOD/', '') 
            } */}
        </ListItem>
    </Box>
)

const Lists = (props) => {
    const history = useHistory();
    const [selected, setSelected] = useState(undefined)
    const [cursor, setCursor] = useState(0)
    const [hovered, setHovered] = useState(undefined)
    const items = props.productCodes
    const { feedSource, feedDatabaseCode } = props
    const openStock = (item) => {
        if (item !== undefined && item.product_code !== undefined && item.series_type !== undefined) {
            if (feedSource === '' || feedDatabaseCode === '' || item.product_code === '') {
                return
            }
            // dispatch(setProductData(item))
            history.push(`${productPath}/${encodeURIComponent(feedSource)}/${encodeURIComponent(feedDatabaseCode)}/${encodeURIComponent(item.product_code)}`)
        }
    }

    useEffect(() => {
        if (items.length && hovered) {
            setCursor(items.indexOf(hovered))
        }
    }, [hovered])

    const clickHandler = (item) => {
        console.log(feedSource, feedDatabaseCode, item.product_code)
        if (feedSource === '' || feedDatabaseCode === '') {
            return
        }
        let innerProductPath = productPath
        if(feedSource === 'BEA'){
            innerProductPath += '/detail'
        }
        if(item.product_code === ''){
            history.push(`${innerProductPath}/${encodeURIComponent(feedSource)}/${encodeURIComponent(feedDatabaseCode)}/${encodeURIComponent('')}`)
        }else{
            props.handleProductCode(item.product_code)
            history.push(`${innerProductPath}/${encodeURIComponent(feedSource)}/${encodeURIComponent(feedDatabaseCode)}/${encodeURIComponent(item.product_code)}`)
            // dispatch(setProductData(item))
        }
        setCursor(items.indexOf(item))
    }

    let pressedKeys = {}

    //When use press key
    function keyDownHandler(e) {
        pressedKeys[e.keyCode] = true
        if (items.length) {
            if (e.key === 'ArrowDown') {
                setCursor(prevState => {
                    return prevState < items.length - 1 ? cursor + 1 : cursor
                })
            } else if (e.key === 'ArrowUp') {
                setCursor(prevState => {
                    return prevState > 0 ? prevState - 1 : prevState
                })
            }
        }
    }

    //When user release key pressing
    function keyUpHandler(e) {
        pressedKeys[e.keyCode] = false
        const item = items[cursor]
        openStock(item)
    }

    React.useEffect(() => {
        window.addEventListener("keydown", keyDownHandler)
        window.addEventListener("keyup", keyUpHandler)
        return () => {
            if (window && window.removeEventListener) {
                window.removeEventListener("keydown", keyDownHandler)
                window.removeEventListener("keyup", keyUpHandler)
            }
        }
    })
    const selectedProductName = useSelector(state => state.productData.productName)

    return (
        <>
            {items.map((item, i) => (
                <ListItemComponent
                    feedSource={feedSource}
                    key={i}
                    active={cursor === 0 ? (selectedProductName?.toLocaleLowerCase() === item.product_code?.toLocaleLowerCase()) : i === cursor}
                    item={item}
                    setSelected={setSelected}
                    clickHandler={clickHandler}
                    setHovered={setHovered}
                />
            ))}
        </>
    )
}

const StockProductCodes = (props) => {
    const dispatch = useDispatch();
    const history = useHistory()
    const {
        productCode: mProductCode,
        databaseCode: mDatabaseCode,
        feedSource: mFeedSource,
    } = useParams()
    const ref = useRef(null)
    const isLoading = useSelector(state => state.eodData.loadingInProgress)
    const [isSearching, setIsSearching] = useState(false)
    const stockProducts = useSelector(state => state.eodData.stockProducts)
    const isFull = useSelector(state => state.eodData.isStockProductsFull)
    const { feedSource, feedDatabaseCode, sectionName, searchWord, value: stepIndex } = props
    const [productCode, setProductCode] = useState('')
    const [searchResults, setResults] = useState([])
    const scrollHandler = useCallback(() => {
        if (
            stockProducts.length > 0 &&
            ref.current.scrollTop + ref.current.clientHeight >=
            ref.current.scrollHeight
        ) {
            if (!isFull && !isLoading && !isSearching) {
                dispatch(fetchStockProducts({
                    feed_source: feedSource,
                    database_code: feedDatabaseCode,
                    start_index: stockProducts?.length,
                    section_name: sectionName
                }))
            }
        }
    }, [
        feedSource,
        feedDatabaseCode,
        sectionName,
        stockProducts,
        isFull
    ])
    useEffect(() => {
        if (mProductCode && mDatabaseCode === feedDatabaseCode && mFeedSource === feedSource) {
            setProductCode(mProductCode)
        }
        if (ref.current) {
            ref.current.addEventListener("scroll", scrollHandler)
        }
        return () => {
            if (ref.current && ref.current.removeEventListener) {
                ref.current.removeEventListener('scroll', scrollHandler)
            }
            setProductCode('')
        }
    }, [
        ref,
        mProductCode,
        mDatabaseCode,
        mFeedSource,
        stockProducts,
        isFull
    ]);
    useEffect(() => {
        if (feedSource && feedDatabaseCode && stepIndex === 3) {
            if (searchWord === '') {
                // this.props.clearStockProducts()
                dispatch(clearStockProducts())
                dispatch(fetchStockProducts({
                    feed_source: feedSource,
                    database_code: feedDatabaseCode,
                    section_name: sectionName,
                    searchWord
                }))
                setResults([])
            } else {
                const params = {
                    search: searchWord,
                    global: false
                }
                if (feedSource !== '') {
                    params.feed_source = feedSource
                }
                if (feedDatabaseCode !== '') {
                    params.database_code = feedDatabaseCode
                }
                // if (sectionName && sectionName !== '') {
                //     params.section_name = sectionName
                // }
                setIsSearching(true)
                try{
                    dispatch(fetchSearchData(params)).then(r => {
                        if (r.payload) {
                            const { suggestions } = r.payload
                            if (Array.isArray(suggestions)) {
                                setResults([...suggestions]);
                            }
                            setIsSearching(false)
                        }
                    })
                }catch(e){
                    setIsSearching(false)
                }
            }
        }
    }, [
        feedSource,
        feedDatabaseCode,
        sectionName,
        searchWord,
        stepIndex
    ])
    const loadingMoreStyle = {
        textAlign: 'center',
        marginTop: '10px',
        marginBottom: '20px'
    }

    return (
        <Box id="ProductCodeWrapper"
            ref={ref}
            style={{ overflow: 'auto', height: '100%' }}
        >
            {
                searchWord !== '' ? (
                    isSearching ? (
                        <Box style={loadingMoreStyle}>Loading...</Box>
                    ) : searchResults.length < 1 ? (
                        <div style={{ marginTop: '10px', marginLeft: '18px' }}>There is no match</div>
                    ) : (
                        <>
                            {
                                searchResults?.map((item, index) => {
                                    return <ListItem
                                        onClick={() => {
                                            const url = `${productPath}/${encodeURIComponent(item.value.feed_source)}/${encodeURIComponent(item.value.database_code)}/${encodeURIComponent(item.value.product_code)}`
                                            history.push(url)
                                        }}
                                        key={index}
                                    >
                                        {item.value.feed_source}/{item.value.database_code}/{item.value.product_code}
                                    </ListItem>
                                })
                            }
                        </>
                    )
                ) : (
                    <>
                        <Lists productCodes={stockProducts}{...props} /> {
                            isLoading ? <Box style={loadingMoreStyle}>Loading...</Box> : ''
                        }
                    </>
                )
            }
        </Box>
    )
}

export default StockProductCodes
