import React, { useEffect, useRef } from "react";
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
// import Highcharts3D from 'highcharts/highcharts-3d'
import IndicatorsAll from 'highcharts/indicators/indicators-all'

import 'highcharts/css/stocktools/gui.scss'
import 'highcharts/css/annotations/popup.scss'

import StockData from 'highcharts/modules/data'
import HighchartsAccessibility from 'highcharts/modules/accessibility'
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import DragPane from 'highcharts/modules/drag-panes'
import AnnotationsAdvanced from 'highcharts/modules/annotations-advanced'
import Annotations from 'highcharts/modules/annotations'
import PriceIndicator from 'highcharts/modules/price-indicator'
import FullScreen from 'highcharts/modules/full-screen'
import StockTools from 'highcharts/modules/stock-tools'
import Heikinashi from 'highcharts/modules/heikinashi'
import HollowCandlestick from 'highcharts/modules/hollowcandlestick'
import HistogramBellCurve from 'highcharts/modules/histogram-bellcurve'
import Gantt from 'highcharts/modules/gantt'

import HighchartsMore from 'highcharts/highcharts-more';
import {Box} from "@mui/system";

// Highcharts3D(Highcharts)
StockData(Highcharts)
DragPane(Highcharts)
IndicatorsAll(Highcharts)
StockTools(Highcharts)
Annotations(Highcharts)
AnnotationsAdvanced(Highcharts)
PriceIndicator(Highcharts)
FullScreen(Highcharts)
HighchartsAccessibility(Highcharts)

Heikinashi(Highcharts)
HollowCandlestick(Highcharts)
Gantt(Highcharts)
HistogramBellCurve(Highcharts)

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);


// (function (H) {
//   H.addEvent(H.Chart, 'load', function (e) {
//     const chart = e.target;
//     H.addEvent(chart.container, 'click', function (e) {
//       e = chart.pointer.normalize(e);
//       console.log('Clicked chart at ' + e.chartX + ', ' + e.chartY);
//     });
//     H.addEvent(chart.xAxis[0], 'afterSetExtremes', function (e) {
//       console.log('Set extremes to ' + e.min + ', ' + e.max);
//     });
//   });
// }(Highcharts));

function ChartContainer(props) {
  const { options } = props
  const chartRef = useRef(null);
  const boxRef = useRef(null);

  // useEffect(() => {
  //   if (chartRef.current) {
  //     const chart = chartRef.current.chart;
  //     const point = chart.series[0].points[0]; // Get the point you want to add the icon to
  //     console.log(point, 'point')
  //     chart.renderer.image(
  //       'data:image/svg+xml;base64,' + btoa(
  //         '<svg width="20" height="20" viewBox="0 0 20 20"><path d="M10 0L0 20L20 20Z" fill="red" /></svg>'
  //       ),
  //       point.plotX - 10, // Adjust position as needed
  //       point.plotY - 10, // Adjust position as needed
  //       20,
  //       20
  //     ).add();
  //   }
  // }, []);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (chartRef.current) {
        chartRef.current.chart.reflow();
      }
    });

    if (boxRef.current) {
      observer.observe(boxRef.current);
    }

    return () => {
      if (boxRef.current) {
        observer.unobserve(boxRef.current);
      }
    };
  }, []);

  return (
    <Box
      ref={boxRef}
      style={{ width: '100%',marginTop: '30px' }}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...options,
          exporting: {
            enabled: false
          }
        }}
        constructorType={'stockChart'}
        ref={chartRef}
      />
    </Box>
  )
}

export default ChartContainer