import React, {useState, useContext, useEffect, useMemo, useCallback} from "react";
import { ProductContext } from "./ProductContext";
import {useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  fetchProductSQL,
  initProductMetaData,
  setSelectedBlobTimeIncrement,
  setSelectedDataOpt,
  setProductOpt,
  fetchStockProductData,
  fetchStockProductOfBigQuery,
  setSelectedFrequencies,
  setXAxisFields,
  setYAxisFields,
  setXAxis,
  setFields,
  addSeries,
  clearAllSeries,
  setInitialChartConfigs,
  initialChart
} from "store/slices/StockProductSlice";
import utils from "shared/utilities/product";
import {getRandomColor} from "shared/utilities/common";
import {parseDateToLocalFormat} from "../../shared/utilities/dateHelpers";

const TIMESEARIES_SOURCES = ['BLS', 'BOE', 'BEA', 'BCB', 'BP', 'CFTC', 'HKEX', 'RATEINF', 'ML', 'FISCAL_DATA', 'OECD', 'MULTPL', 'EIA', 'USTREASURY', 'ECONODAY', 'QUANDL', 'URL', 'FRED', 'BATS', 'YC', 'QOR', 'ZILLOW', 'SGE', 'ODA', 'FINRA', 'WB', 'WASDE', 'ECONOMIST', 'BCHAIN']
const NDL_SOURCES = ['BEA', 'ECONODAY', 'FRED', 'BATS', 'BCIW', 'YC', 'BCMX', 'QOR', 'ZILLOW', 'BCEUX', 'SGE', 'ODA', 'FINRA', 'WB', 'WASDE']

export const useProductContext = () => {
  const context = useContext(ProductContext);
  if (context === undefined) {
    throw new Error('useDataContext must be used within a DataProvider');
  }
  return context;
};

function ProductProvider(props) {
    const [data, setData] = useState([]);
    const [feedSourceType, setFeedSourceType] = useState('stock')
    const { feedSource, databaseCode, productCode = '' } = useParams()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const bookmarkedXAxis = searchParams.get('xAxis');
    // const bookmarkedYAxis = searchParams.get('yAxis');
    const bookMarkedTimeIncrement = searchParams.get('timeIncrement');
    const [initialized, setInitialized] = useState(false)
    const {
      qaMode,
      selectedBlobTimeIncrement: timeIncrement,
      bgTables,
      loadedProductData,
      selectedDataOpt,
      timeIncrements,
      signedUrls,
      chartSettings,
      xAxis,
      series,
      selectedChart,
      charts : {
        innerChart,
        globalChart
      }
    } = useSelector(state => state.productData)
    const user = useSelector(state => state.user.user)
    const dispatch = useDispatch()
    const [xAxisType, setXAxisType] = useState(null)
    const [defaultChart, setDefaultChart] = useState({})

    // Consider to remove
    useEffect(() => {
      if (TIMESEARIES_SOURCES.indexOf(feedSource.toUpperCase()) > -1) {
        setFeedSourceType('timeseries')
      }else{
        setFeedSourceType('stock')
      }
    }, [
      feedSource
    ])
  const beaDatabaseCode = useMemo(() => {
      const transformeds = ['NIPA', 'FixedAssets', 'NIUnderlyingDetail', 'IO', 'GDPbyIndustry', 'underlyingGDPbyIndustry', 'IIP', 'IntlServTrade', 'ITA', 'MNE']
      if (databaseCode === 'ITA' && productCode === 'TOTAL') return ''
      if (feedSource === 'BEA' && transformeds.indexOf(databaseCode) > -1) {
        return databaseCode
      }
      return ''
    }, [
      feedSource, databaseCode
    ])
    const isNDLSource = useMemo(() => {
      return NDL_SOURCES.indexOf(feedSource.toUpperCase()) > -1
    })

    const identifier = useMemo(() => {
      let _identifier = `${selectedDataOpt === 'big-query' ? 'bg/' : ''}${feedSource}/${databaseCode}/${productCode}`
      if (timeIncrement) {
        _identifier += `/${timeIncrement}`
      }
      return _identifier
    }, [feedSource, databaseCode, productCode, timeIncrement, selectedDataOpt])

    const fetchProductData = useCallback(() => {
      if (!(loadedProductData && loadedProductData[identifier] && loadedProductData[identifier].length > 0) && beaDatabaseCode === '' && initialized) {
        // if (timeIncrement) {
        // let tPromise =  new Promise(resolve => {
        const _params = {
          feed_source: feedSource,
          database_code: databaseCode,
          product_code: productCode,
          timeIncrement: timeIncrement,
          bg_table_location: bgTables[timeIncrement] || ''
        }
        try {
          if (selectedDataOpt === 'big-query') {
            dispatch(fetchStockProductOfBigQuery(_params))
          } else {
            dispatch(fetchStockProductData(_params))
          }
        } catch (e) {
          console.log('Error:', e)
        }
        // });
        // await tPromise
        // }
      }
    }, [
      beaDatabaseCode,
      loadedProductData,
      identifier,
      feedSource,
      databaseCode,
      productCode,
      bgTables,
      timeIncrement,
      initialized
    ])

    useEffect(() => {
      const _qa = JSON.parse(new URLSearchParams(location.search).get('qa'));
      dispatch(setProductOpt({
        path: 'qaMode',
        value: _qa ?? false
      }))
    }, [location.search]);
    useEffect(() => {
      if (qaMode !== undefined) {
        if (!qaMode) {
          dispatch(setSelectedDataOpt('cloud-storage'))
          // }
        } else {
          dispatch(setSelectedDataOpt(null))
          dispatch(setSelectedBlobTimeIncrement(null))
        }
      }
    }, [
      qaMode,
      feedSource,
    ])

    useEffect(() => {
      try {
        dispatch(initProductMetaData())
        if (qaMode !== undefined && !qaMode && user.uid) {
          setInitialized(false)
          dispatch(fetchProductSQL({
            feed_source: feedSource,
            database_code: databaseCode,
            product_code: productCode,
            uid: user.uid,
          })).then(({ payload }) => {
            setInitialized(true)
            if(!payload?.product){
              dispatch(setProductOpt({ path: 'loadingStockData', value: false }))
            }
          })
        }
        // dispatch(setFeedSourceType(feedSourceType))
        // dispatch(setLoadedFeedSourceType(feedSourceType))
      } catch (e) {
        console.log(e, 'error')
      }
    }, [
      productCode,
      databaseCode,
      feedSource,
      qaMode,
      // beaDatabaseCode,
      user.uid
    ])
    useEffect(() => {
      setData([])
      dispatch(clearAllSeries())
    }, [identifier]);

    useEffect(() => {
      if (timeIncrement !== null || (qaMode && selectedDataOpt)) {
        if(!qaMode){
          fetchProductData()
        }
      }
    }, [
      timeIncrement,
      selectedDataOpt,
      qaMode,
      initialized
    ])

    const initFetchingData = useCallback(async () => {
      if (!qaMode && initialized) {
        if (beaDatabaseCode !== '') {
          dispatch(setProductOpt({
            path: 'viewMode',
            value: 'datatable'
          }))
          if (timeIncrements) {
            // fetchSignedUrl([timeIncrements[0]])
            // await fetchAllProductData()
            dispatch(setSelectedFrequencies([timeIncrements[0]]))
            // console.log(productData.signedUrls, 'productData.signedUrls')
            // executeDataTransformLogic()
          }
        } else {
          if (timeIncrements) {
            if (bookMarkedTimeIncrement) {
              dispatch(setSelectedBlobTimeIncrement(bookMarkedTimeIncrement))
            }else if (!timeIncrement && timeIncrements && (timeIncrements[0] !== undefined)) {
              // }else if (!timeIncrement && timeIncrements && (timeIncrements !== [''])) {
              dispatch(setSelectedBlobTimeIncrement(timeIncrements[0]))
            }
          }
        }
      }
    }, [
      timeIncrements,
      signedUrls,
      timeIncrement,
      qaMode,
      beaDatabaseCode,
      selectedDataOpt,
      bookMarkedTimeIncrement,
      initialized,
    ])
    useEffect(() => {
      initFetchingData()
    }, [
      timeIncrements,
      timeIncrement,
      qaMode,
      selectedDataOpt,
      initialized
    ])

  const formatStock = (data) => {
    const _data = JSON.parse(JSON.stringify(data))
    _data.forEach((d) => {
      let sDate = null
      if (d.reading_date) {
        sDate = new Date(d.reading_date)
      } else if (d.timestamp) {
        sDate = new Date(d.timestamp)
      } else if (d.date) {
        sDate = new Date(d.date)
      }
      d.date = sDate
      d.close = +d.close
      d.open = +d.open
      d.high = +d.high
      d.low = +d.low
      d.volume = +d.volume
      d.absoluteChange = ""
      d.dividend = ""
      d.percentChange = ""
      d.split = ""
    })
    return _data
  }

  const formatNonMarket = (initials, options = {}) => {
    const res = [];
    const { xType, xConfiguration } = options;
    if (Array.isArray(initials)) {
      initials.map(item => {
        const _record = {}
        if (xType && xType === 'customized') {
          let customizedDate = null
          for (let xConfig of xConfiguration) {
            if (customizedDate !== null) break;
            if (xConfig.defaultDate) {
              if (new Date(item[xConfig.defaultDate]).toDateString() !== 'Invalid Date') {
                customizedDate = utils.parsePotentialData(item[xConfig.defaultDate])
              }
            } else if (xConfig.start_date) {
              const year = new Date(item[xConfig.start_date])
              if (year.toDateString() !== 'Invalid Date') {
                if (isNaN(item[xConfig.day])) {
                  if (new Date(item[xConfig.day]).toDateString() !== 'Invalid Date') {
                    customizedDate = utils.parsePotentialData(item[xConfig.day])
                  }
                } else {
                  customizedDate = [year.getFullYear(), item[xConfig.month], item[xConfig.day]].join('-');
                }
              }
            }
          }
          if (customizedDate && new Date(customizedDate).toDateString() !== 'Invalid Date') {
            _record.customized = customizedDate;
          } else {
            _record.customized = undefined;
          }
        }
        for (let key in item) {
          _record[key.toLocaleLowerCase()] = item[key]
        }
        res.push(_record)
      })
    }
    return res;
  }

  const formatToChartable = (value) => {
    let _value = value
    if (_value == null) {
      return _value
    }
    if (typeof _value === 'object' && _value.hasOwnProperty('value')) {
      _value = _value.value
    }
    if (_value == null) {
      return _value
    }
    if (!isNaN(_value)) {
      return +_value
    }
    if (!isNaN(Date.parse(_value))) {
      const fDate = new Date(_value)
      return [fDate.getFullYear(), fDate.getMonth() + 1, fDate.getDate()].join('-')
    }
    return _value
  }
  const deDuplicate = (res) => {
    let list = []
    let deDuplicated = []
    let _data
    if (res.data) {
      _data = res.data
    } else _data = [...res]
    _data.forEach(element => {
      if (!list.includes(JSON.stringify(element))) {
        deDuplicated.push(element)
        list.push(JSON.stringify(element))
      }
    })
    return deDuplicated
  }

  const formatData = async () => {
    let verifiedX = null, verifiedY = null;
    if (loadedProductData[identifier] && loadedProductData[identifier].length > 0) {
      let initialData = deDuplicate(JSON.parse(JSON.stringify(loadedProductData[identifier])))
      // dispatch(initiTimeseriesData())
      // dispatch(setFields([]))
      let isEvaluated = false
      initialData.map((d) => {
        /** Customize the data into chart format, and get the verified X & Y based on default settings*/
        for (let field in d) {
          d[field] = formatToChartable(d[field])
          if (!isEvaluated) {
            isEvaluated = true
          }
        }
        return utils.replaceSpace(d)
      })
      //Update settings
      if (initialData.length > 0) {
        let innerFeedSourceType
        const { fields: innerFields } = utils.attributeTransformer(initialData)
        dispatch(setFields([...innerFields]))
        const requiredIds = ['open', 'low', 'close', 'high'];
        const hasValidId = requiredIds.every(requiredId => innerFields.some(item => item.id === requiredId));
        if (!hasValidId) {
          innerFeedSourceType = 'timeseries'
          setFeedSourceType('timeseries')
        }else{
          innerFeedSourceType = 'stock'
          setFeedSourceType('stock')
        }

        let xAxisFields = innerFields.filter(f => ['date', 'datenumber', 'string'].indexOf(f.type) > -1)
        let yAxisFields = innerFields.filter(f => ['number', 'datenumber'].indexOf(f.type) > -1)
        let innerXAxis =null;
        dispatch(setXAxisFields([...xAxisFields]))
        dispatch(setYAxisFields([...yAxisFields]))

        if(!innerChart){
          let innerDefaultChart = JSON.parse(JSON.stringify(initialChart))
          if (innerFeedSourceType !== 'stock') {
            if(bookmarkedXAxis){
              innerXAxis = bookmarkedXAxis
            }else if (xAxisFields[0] && xAxisFields[0].id) {
              innerXAxis = xAxisFields[0].id
            }
            innerDefaultChart.series.push({
              id: 'default',
              value: yAxisFields[0].id
            })
            innerDefaultChart.chartSettings.colors = [getRandomColor()]
            // if(bookmarkedYAxis){
            //   dispatch(setYAxis(bookmarkedYAxis.split(',')))
            // } else if (yAxisFields[0] && yAxisFields[0].id) {
            //   dispatch(setYAxis([yAxisFields[0].id]))
            // }
          }else{
            innerDefaultChart.series.push({
              id: 'default',
              value: 'close'
            })
            innerDefaultChart.chartSettings.colors = [getRandomColor(), getRandomColor()]
            const xAxisIdx = xAxisFields.findIndex(item => item.id === 'date' || item.id === 'timestamp')
            if(xAxisIdx > -1){
              innerXAxis = xAxisFields[xAxisIdx].id
            }else{
              innerXAxis = xAxisFields[0].id
            }
            innerDefaultChart.chartType = 'ohlc'
          }
          innerXAxis = innerXAxis ?? innerFields[0]
          innerDefaultChart.xAxis = innerXAxis
          setDefaultChart(innerDefaultChart)
        }

        if(innerChart){
          innerXAxis = innerChart.configs.xAxis
          dispatch(setProductOpt({
            path: 'selectedChart', value: 'inner'
          }))
        }else if(globalChart){
          innerXAxis = globalChart.configs.xAxis
          dispatch(setProductOpt({
            path: 'selectedChart', value: 'global'
          }))
        }else{
          dispatch(setProductOpt({
            path: 'selectedChart', value: 'inner'
          }))
        }
        const innerXAxisType = xAxisFields.filter(item => item.id === innerXAxis)[0]?.type
        if(['date', 'datenumber'].includes(innerXAxisType)){
          initialData = initialData.sort((a, b) => {
            if(a[innerXAxis] && b[innerXAxis]){
              return new Date(a[innerXAxis]).getTime() - new Date(b[innerXAxis]).getTime()
            }
            return 1;
          })
        }
        setXAxisType(innerXAxisType)
        setData([...initialData])
      }
    }

  }

  useEffect(() => {
    formatData()
  }, [
    loadedProductData,
    identifier,
    selectedDataOpt,
  ])
  useEffect(() => {
    if(selectedChart === 'inner'){
      if(innerChart){
        dispatch(setInitialChartConfigs(innerChart.configs))
      }else{
        dispatch(setInitialChartConfigs(defaultChart))
      }
    }else if(selectedChart === 'global'){
      dispatch(setInitialChartConfigs(globalChart.configs))
    }
  }, [
    selectedChart,
    innerChart,
    globalChart,
    defaultChart
  ]);

  const clickBlobHandler = (blob) => {
    if (timeIncrements && !timeIncrement) {
      if (timeIncrements && (timeIncrements[0] !== undefined) && beaDatabaseCode === '' && timeIncrement === null) {
        // if (timeIncrements && (timeIncrements !== ['']) && beaDatabaseCode === '' && timeIncrement === null) {
        dispatch(setSelectedBlobTimeIncrement(timeIncrements[0]))
      }
    }
    dispatch(setSelectedDataOpt(blob))
  }

  const addChartColors = () => {
    const colorLength = chartSettings?.colors?.length || 0
    dispatch(setProductOpt({
      path: `chartSettings.colors.${colorLength}`, value: getRandomColor()
    }))
  }

  const defaultHighlights = useMemo(() => {
    if(data && data[0] && series?.length > 0){
      const yAxisField = series[0].value
      console.log(data, 'data')
      // console.log(data[0][xAxis], data[100][xAxis], 'data[0]')
      // console.log(xAxis, 'xAxis')
      let endIdx = 50
      if(data.length <= 50) endIdx = Math.floor(data.length/2)
      if(feedSourceType === 'stock'){
        return {
          axis: 'x',
          type: 'band',
          dashStyle: 'solid',
          fromY: data[0][yAxisField],
          toY: data[endIdx][yAxisField],
          fromX: new Date(data[0][xAxis]),
          toX: new Date(data[endIdx][xAxis])
        }
      }else{
        return {
          axis: 'x',
          type: 'band',
          dashStyle: 'solid',
          fromY: data[0][yAxisField],
          toY: data[3][yAxisField],
          fromX: xAxisType === 'date' ? parseDateToLocalFormat(data[data.length - 4][xAxis]) : data[data.length - 4][xAxis],
          toX: xAxisType === 'date' ? parseDateToLocalFormat(data[data.length - 1][xAxis]) : data[data.length - 1][xAxis]
        }
      }
    }
    return null
  }, [
    xAxis,
    data,
    series,
    feedSourceType,
    xAxisType
  ])

  return (
    <ProductContext.Provider value={{
      data,
      setData,
      identifier,
      feedSource,
      databaseCode,
      productCode,
      beaDatabaseCode,
      feedSourceType,
      isNDLSource,
      clickBlobHandler,
      addChartColors,
      defaultHighlights
    }}>
      {props.children}
    </ProductContext.Provider>
  );
}

export default ProductProvider