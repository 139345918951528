import {Box, Divider, List, ListItem, ListItemButton, ListItemText, Popover, Typography} from "@mui/material";
import {ArrowDropDown, Check as CheckIcon} from "@mui/icons-material";
import React, {useMemo, useState} from "react";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
  Txt: {
    color: 'black'
  },
  ListItemText: {
    fontSize: 14,
    textTransform: 'capitalize'
  },
  ListItemButton: {
    padding: '5px 11px'
  }
}));

interface DropdwonSelectProps {
    placeholder?: string
    label?: string;
    options?: any[]
    selectedValue?: any
    onChange: (value: string) => void;
}

export const DropdownSelect: React.FC<DropdwonSelectProps> = ({
  label,
  placeholder,
  options = [],
  selectedValue = '',
  onChange,
  ...rest
}) => {

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const handleFieldClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const openPop = Boolean(anchorEl);
  const selectedOption = useMemo(() => {
    return options.filter(item => item.id === selectedValue)[0]

  }, [
    options,
    selectedValue
  ])

  return <Box {...rest}>
      {
          label && label !== '' && (
              <Typography className={classes.Txt} mt={1}>{ label }</Typography>
          )
      }
    <Box
      display="flex"
      sx={{
        border: 'solid 1px #dfd4d4',
        borderRadius: '4px',
        overflow: 'hidden',
        cursor: 'pointer',
        height: '38px',
        alignItems: 'center',
      }}
      onClick={handleFieldClick}
    >
      <Box flex={1}
           display="flex"
           sx={{
             height: '100%',
             alignItems: 'center',
           }}
      >
        <Box sx={{
          height: '100%',
          width: '9px',
          background: '#1976d2',
          marginRight: '8px'
        }}></Box>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {
              placeholder && (
                <span>{ placeholder }</span>
              )
            }
            <span style={{ fontSize: '11px', fontWeight: 'bold', textTransform: 'capitalize' }}>{ selectedOption?.label || selectedOption?.id}</span>
          </Box>
        {/*<Typography className={classes.Txt} sx={{ fontSize: '13px' }}>{selectedValue ? selectedValue.toUpperCase() : placeholder }</Typography>*/}
      </Box>
      <ArrowDropDown />
    </Box>
    <Popover
      open={openPop}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      // transformOrigin={{
      //     vertical: 'bottom',
      //     horizontal: 'left',
      // }}
    >
      <Box sx={{ width: '270px', maxHeight: '300px', bgcolor: 'background.paper' }}>
        <Divider />
        <nav>
          <List>
            {
              options.map((item, idx) => (
                <ListItem key={idx} disablePadding>
                  <ListItemButton
                    classes={{ root: classes.ListItemButton }}
                    onClick={() => {
                      setAnchorEl(null)
                      onChange(item.id)
                    }}
                  >
                    <ListItemText
                      classes={{ primary: classes.ListItemText }}
                      primary={item.label ? item.label : (item.id ? item?.id?.split('_').join(" ") : '')}
                    />
                      {
                          item.id === selectedValue && <CheckIcon />
                      }
                  </ListItemButton>
                </ListItem>
              ))
            }
          </List>
        </nav>
      </Box>
    </Popover>
  </Box>
}