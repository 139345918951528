import React, {useMemo, useRef} from "react";
// import { useSelector } from 'react-redux'

import {parseDateToLocalFormat} from "shared/utilities/dateHelpers";
import NonMarketHighChart from "views/product/details/chart/NonMarketHighChart";
import {useDispatch, useSelector} from "react-redux";
import {addAnnotation, setProductOpt, updatePlotHighlights} from "store/slices/StockProductSlice";

const pathPoints = "M-4.5,0.5 L3.5,0.5 L3.5,15.5 L-4.5,15.5 L-4.5,0.5 M-1.5,4 L-1.5,12 M0.5,4 L0.5,12";
const pathAttribute = {
  d: pathPoints,
  stroke: '#999999',
  'stroke-width': 1,
  fill: '#f2f2f2',
  width: 16,
  height: 16,
  cursor: 'col-resize'
}

function LineChart(props) {
  const {
    data: initialData,
    xAxis,
    xAxisType,
    yAxisFields,
    chartType,
    defaultConfigs,
    dataLabelsData,
    tooltipFormatData,
  } = props
  const dispatch = useDispatch()

  const annotations = useSelector(state => state.productData.annotations)
  const plotHighlights = useSelector(state => state.productData.plotHighlights)
  const activeChartAnchor = useSelector(state => state.productData.activeChartAnchor)

  const dragging = useRef(null)
  const draggingAxis = useRef(null)
  const draggingId = useRef(null)
  const clickX = useRef(null)
  const clickY = useRef(null)
  const translateX = useRef(0)
  const translateY = useRef(0)
  const plotResizeHandlerRef = useRef(null)
  const plotBandAnchor = useRef('move')
  const plotBandResizeHandler = useRef(null)


  const resizePath = (currentD, changeAmount, direction) => {
    const moves = currentD.split('M ').filter(item => item !== '')
    let newD = ''
    for(let m of moves){
      const points = m.split(' ');
      let x1 = parseFloat(points[0]);
      let y1 = parseFloat(points[1]);
      let x2 = parseFloat(points[3]);
      let y2 = parseFloat(points[4]);
      let x3 = parseFloat(points[6]);
      let y3 = parseFloat(points[7]);
      let x4 = parseFloat(points[9]);
      let y4 = parseFloat(points[10]);
      if(direction === 'resize-from-left'){
        x1 += changeAmount;
        x2 += changeAmount;
      }else if(direction === 'resize-from-right'){
        x3 += changeAmount;
        x4 += changeAmount;
      }else if(direction === 'resize-from-top'){
        y1 += changeAmount;
        y2 += changeAmount;
      }else if(direction === 'resize-from-bottom'){
        y3 += changeAmount;
        y4 += changeAmount;
      }
      newD += 'M ' + x1 + ' ' + y1 + ' L ' + x2 + ' ' + y2 + ' L ' + x3 + ' ' + y3 + ' L ' + x4 + ' ' + y4 + ' Z '
    }

    return newD;
  }


  const data = useMemo(() => {
    if(xAxisType === 'date'){
      return initialData.sort((a, b) => {
        if (a[xAxis] && b[xAxis]) {
          return new Date(a[xAxis]).getTime() - new Date(b[xAxis]).getTime()
        }
        return 1;
      })
    }
    return initialData
  }, [
    initialData,
    xAxis,
    xAxisType
  ])

  const categories = useMemo(() => {
    if(xAxisType === 'date'){
      console.log(xAxis, 'inside categories')
      return data.map(item => parseDateToLocalFormat(item[xAxis]))
    }
    return data.map(item => item[xAxis])
  }, [
    data,
    xAxis,
    xAxisType
  ])
  console.log(categories, 'categories')

  const onAddAnnotation = (event) => {
    dispatch(setProductOpt({ path: 'activeChartAnchor', value: null }))
    if(activeChartAnchor === 'annotation'){
      console.log(event.xAxis, 'event.xAxis')
      dispatch(addAnnotation({
        point: {
          xAxis: 0,
          yAxis: 0,
          x: event.xAxis[0].value,
          y: event.yAxis[0].value
        },
        isFresh: true,
        text: 'Insert Text'
      }))
    }
  }

  const onMouseDown = (e, that, item, axis) => {
    if(!activeChartAnchor){
      dragging.current = that;
      draggingAxis.current = axis
      draggingId.current = item.id
      clickX.current = e.layerX;
      clickY.current = e.layerY;
      plotBandAnchor.current = 'move'

      if (typeof that.svgElem?.translateX == 'number')
        translateX.current = that?.svgElement?.translateX;

      if (typeof that.svgElem?.translateY == 'number')
        translateY.current = that?.svgElement?.translateY || 0;
    }
  }

  const xPlotBands = plotHighlights.filter(item => item.axis === 'x' && item.type === 'band').map(item => {
    return {
      ...item,
      from: new Date(item.fromX),
      to: new Date(item.toX),
      events: {
        mousedown: function(e){
          onMouseDown(e, this, item, 'x')
        },
        click: function(event) {
          onAddAnnotation(event)
        }
      }
    }
  })
  const yPlotBands = plotHighlights.filter(item => item.axis === 'y' && item.type === 'band').map(item => {
    return {
      ...item,
      from: item.fromY,
      to: item.toY,
      events: {
        mousedown: function(e){
          onMouseDown(e, this, item, 'y')
        },
        click: function(event) {
          onAddAnnotation(event)
        }
      }
    }
  })
  const xPlotLines = plotHighlights.filter(item => item.axis === 'x' && item.type === 'line').map(item => {
    return {
      ...item,
      width: 2,
      value: item.fromX,
      zIndex: 2,
      events: {
        mousedown: function(e){
          onMouseDown(e, this, item, 'x')
        },
      }
    }
  })
  const yPlotLines = plotHighlights.filter(item => item.axis === 'y' && item.type === 'line').map(item => {
    return {
      ...item,
      width: 2,
      value: item.fromY,
      zIndex: 2,
      events: {
        mousedown: function(e){
          onMouseDown(e, this, item, 'y')
        },
      }
    }
  })

  const chartMinWidth = useMemo(() =>{
    if(data.length > 100){
      return {
        minWidth: 1900,
        scrollPositionX: 1
      }
    }
    return {}
  }, [
    data
  ])

  const series = useMemo(() => {
    const duelSeries = {}
    if (!(xAxis && yAxisFields && yAxisFields.length > 0)) return [{}]
    if (yAxisFields?.length > 0) {
      console.log(yAxisFields, 'yAxisFields')
      console.log(dataLabelsData, 'datalabesData')
        yAxisFields.map((chartLine) => {
          if(chartLine?.value){
            const formatBody = tooltipFormatData[chartLine?.value] || tooltipFormatData['all']
            duelSeries[chartLine.value] = {
              ...chartLine,
              type: chartLine.chartType || 'line',
              name: chartLine.value.split('_').join(' ').toUpperCase(),
              data: data.map(item => item[chartLine.value]),
              tooltip: {
                valueDecimals: 2,
                pointFormat: `${formatBody.pointFormatBody}`
              },
            }
            if(chartLine?.marker?.symbol && chartLine?.marker?.symbol !== 'none'){
              duelSeries[chartLine.value].marker = {
                enabled: true,
                symbol: chartLine?.marker?.symbol,
                radius: 3
              }
            }
            console.log(chartLine.value, 'chartLIne.value')
            if(dataLabelsData[chartLine?.value]){
              duelSeries[chartLine.value].dataLabels = dataLabelsData[chartLine.value]
            }
          }
          // const chartLine = chartLinesData?.filter(item => item.id === chartLineId)[0]
          // if (chartLine) {
          //   if (item[chartLineId]) {
          //     if (duelSeries[chartLineId] === undefined) {
          //       duelSeries[chartLineId] = {
          //         name: chartLine.label.toUpperCase(),
          //         color: getRandomColor(),
          //         data: []
          //       }
          //     }
          //     const duelPoint = [_dateVal, isNaN(item[chartLineId]) ? 0 : item[chartLineId]]
          //     duelSeries[chartLineId].data.push(duelPoint)
          //   }
          // }
        })
    }
    // console.log(duelSeries, 'duelSeries')
    // console.log(xAxis, yAxisFields, 'yAxisFields')
    return Object.values(duelSeries)

  }, [
    categories,
    data,
    dataLabelsData,
    yAxisFields,
    xAxis,
  ])
  console.log(xPlotBands, 'xPlotBands')
  const chartConfig = useMemo(() => {
    return {
      ...defaultConfigs,
      chart: {
        ...defaultConfigs.chart,
        events: {
          render: function () {
            const chart = this;
            const innerXPlotBands = chart.xAxis[0].plotLinesAndBands;
            const innerYPlotBands = chart.yAxis[0].plotLinesAndBands;

            if(plotResizeHandlerRef?.current?.destroy){
              plotResizeHandlerRef.current.destroy()
            }
            plotResizeHandlerRef.current = chart.renderer.g().attr({
              'data-z-index': 1
            }).add();
            innerXPlotBands.forEach((plotBand) => {
              if(plotBand.options.type === 'band'){
                const bbox = plotBand.svgElem.getBBox();
                const leftMiddle = bbox.x;
                const rightMiddle = bbox.x + bbox.width;
                const yPosition = bbox.y + bbox.height / 2 - 8
                const leftIcon = chart.renderer.path()
                  .attr({
                    ...pathAttribute,
                    'data-id': plotBand.id,
                    transform: `translate(${leftMiddle}, ${yPosition})`,
                  }).add(plotResizeHandlerRef.current).toFront()

                const rightIcon = chart.renderer.path()
                  .attr({
                    ...pathAttribute,
                    'data-id': plotBand.id,
                    transform: `translate(${rightMiddle}, ${yPosition})`,
                  }).add(plotResizeHandlerRef.current).toFront()

                leftIcon.on('mousedown', function (e) {
                  dragging.current = plotBand
                  draggingAxis.current = 'x'
                  plotBandAnchor.current = 'resize-from-left'
                  draggingId.current = plotBand.id
                  clickX.current = e.layerX;
                  clickY.current = e.layerY;

                  plotBandResizeHandler.current = this
                });
                rightIcon.on('mousedown', function (e) {
                  dragging.current = plotBand
                  draggingAxis.current = 'x'
                  plotBandAnchor.current = 'resize-from-right'
                  draggingId.current = plotBand.id
                  clickX.current = e.layerX;
                  clickY.current = e.layerY;

                  plotBandResizeHandler.current = this
                });
              }
            })
            innerYPlotBands.forEach((plotBand) => {
              if(plotBand.options.type === 'band'){
                const bbox = plotBand.svgElem.getBBox();
                const topMiddle = bbox.y;
                const bottomMiddle = bbox.y + bbox.height;
                const xPosition = bbox.x + bbox.width / 2 - 8
                const topIcon = chart.renderer.path()
                  .attr({
                    ...pathAttribute,
                    'data-id': plotBand.id,
                    cursor: 'row-resize',
                    transform: `translate(${xPosition}, ${topMiddle}) rotate(90)`
                  }).add(plotResizeHandlerRef.current).toFront()

                const bottomIcon = chart.renderer.path()
                  .attr({
                    ...pathAttribute,
                    'data-id': plotBand.id,
                    cursor: 'row-resize',
                    transform: `translate(${xPosition}, ${bottomMiddle}) rotate(90)`,
                  }).add(plotResizeHandlerRef.current).toFront()

                topIcon.on('mousedown', function (e) {
                  dragging.current = plotBand
                  plotBandAnchor.current = 'resize-from-top'
                  draggingId.current = plotBand.id
                  clickX.current = e.layerX;
                  clickY.current = e.layerY;
                  draggingAxis.current = 'y'

                  plotBandResizeHandler.current = this
                });
                bottomIcon.on('mousedown', function (e) {
                  dragging.current = plotBand
                  plotBandAnchor.current = 'resize-from-bottom'
                  draggingId.current = plotBand.id
                  clickX.current = e.layerX;
                  clickY.current = e.layerY;
                  draggingAxis.current = 'y'

                  plotBandResizeHandler.current = this
                });
              }
            })
          },
          load: function() {
            const chart = this;

            this.container.onmousemove = (e) => {
              if (dragging?.current?.svgElem) {
                // const offset = chart.xAxis[0].translate(clickX.current - chart.plotLeft, true)
                const bBox = dragging.current.svgElem.getBBox()
                if(draggingAxis.current === 'x'){
                  if (plotBandAnchor.current === 'move') {
                    const x = e.layerX - clickX.current + translateX.current;
                    if (dragging.current.svgElem.translate) {
                      dragging.current.svgElem.translate(x, 0);
                    }
                    /// Moving Resize Handlers corresponding Plot Band movement
                    const resizeHandlers = document.querySelectorAll(`[data-id='${draggingId.current}']`)
                    const leftHandlerX = bBox.x + x
                    console.log(bBox, x, 'translate ')
                    const rightHandlerX = leftHandlerX + bBox.width
                    const yPosition = bBox.y + bBox.height / 2 - 8
                    if(resizeHandlers[0]){
                      resizeHandlers[0].setAttribute('transform', `translate(${leftHandlerX}, ${yPosition})`)
                    }
                    if(resizeHandlers[1]){
                      resizeHandlers[1].setAttribute('transform', `translate(${rightHandlerX}, ${yPosition})`)
                    }
                  }else if(['resize-from-left', 'resize-from-right'].includes(plotBandAnchor.current)){
                    const {
                      width: currentWidth,
                      x: translateX,
                      y: translateY,
                      height: currentHeight
                    } = dragging.current.svgElem.getBBox();
                    let x
                    if(plotBandAnchor.current === 'resize-from-left') {
                      x = e.layerX;
                    }else {
                      x = e.layerX - currentWidth;
                    }

                    const currentD = dragging.current.svgElem['d']
                    const newBandD = resizePath(currentD, (x - translateX), plotBandAnchor.current)

                    dragging.current.svgElem.attr({
                      d: newBandD
                    })
                    // Update the X-Position of Resize Handler button
                    const yPosition = translateY + currentHeight / 2 - 8
                    plotBandResizeHandler.current.setAttribute('transform', `translate(${e.layerX}, ${yPosition})`)
                  }
                }else{
                  if(plotBandAnchor.current === 'move') {
                    const y = e.layerY - clickY.current + translateY.current;
                    if (dragging.current.svgElem.translate) {
                      dragging.current.svgElem.translate(0, y);
                    }
                    /// Moving Resize Handlers corresponding Plot Band movement
                    const resizeHandlers = document.querySelectorAll(`[data-id='${draggingId.current}']`)
                    const leftHandlerY = bBox.y + y
                    const rightHandlerY = leftHandlerY + bBox.height
                    const xPosition = bBox.x + bBox.width / 2 - 8
                    if(resizeHandlers[0]){
                      resizeHandlers[0].setAttribute('transform', `translate(${xPosition}, ${leftHandlerY}), rotate(90)`)
                    }
                    if(resizeHandlers[1]){
                      resizeHandlers[1].setAttribute('transform', `translate(${xPosition}, ${rightHandlerY}), rotate(90)`)
                    }
                  }else if(['resize-from-top', 'resize-from-bottom'].includes(plotBandAnchor.current)){
                    const {
                      width: currentWidth,
                      x: innerTranslateX,
                      y: innerTranslateY,
                      height: currentHeight
                    } = dragging.current.svgElem.getBBox();
                    let y
                    if(plotBandAnchor.current === 'resize-from-top') {
                      y = e.layerY;
                    }else {
                      y = e.layerY - currentHeight;
                    }

                    const currentD = dragging.current.svgElem['d']
                    const newBandD = resizePath(currentD, (y - innerTranslateY), plotBandAnchor.current)
                    dragging.current.svgElem.attr({
                      d: newBandD
                    })

                    // Update the X-Position of Resize Handler button
                    const xPosition = innerTranslateX + currentWidth / 2 - 8
                    plotBandResizeHandler.current.setAttribute('transform', `translate(${xPosition}, ${e.layerY}), rotate(90)`)
                  }
                }
              }
            }
            this.container.onmouseup = () => {
              let currentDraggingIdx = -1
              if(draggingAxis.current === 'x'){
                currentDraggingIdx = chart.xAxis[0].plotLinesAndBands.findIndex(item => item.id === draggingId.current)
              }else {
                currentDraggingIdx = chart.yAxis[0].plotLinesAndBands.findIndex(item => item.id === draggingId.current)
              }
              if(currentDraggingIdx > -1){
                if(draggingAxis.current === 'x'){
                  const {
                    x,
                    y,
                    width,
                    height
                  } = chart.xAxis[0].plotLinesAndBands[currentDraggingIdx].svgElem.element.getBoundingClientRect();
                  const {
                    x: chartX,
                    y: chartY
                  } = chart.container.getBoundingClientRect();

                  // console.log(chart.xAxis[0], 'chart.xAxis[0]')
                  const plotBandX = x - chartX,
                    plotBandX2 = x - chartX + width,
                    x1Date = new Date(chart.xAxis[0].toValue(plotBandX)),
                    x2Date = new Date(chart.xAxis[0].toValue(plotBandX2));
                  // console.log(x1Date, 'x1Date')
                  // console.log(x2Date, 'x2Date')

                  // console.log(plotBandAnchor.current, draggingId.current, 'plotBandAnchor.current')
                  if(['move', 'resize-from-right'].includes(plotBandAnchor.current)){
                    dispatch(updatePlotHighlights({
                      id: draggingId.current, path: 'toX', newValue: x2Date
                    }))
                  }
                  if(['move', 'resize-from-left'].includes(plotBandAnchor.current)){
                    dispatch(updatePlotHighlights({
                      id: draggingId.current, path: 'fromX', newValue: x1Date
                    }))
                  }
                }else{
                  const {
                    x,
                    y,
                    width,
                    height
                  } = chart.yAxis[0].plotLinesAndBands[currentDraggingIdx].svgElem.element.getBoundingClientRect();
                  const {
                    x: chartX,
                    y: chartY
                  } = chart.container.getBoundingClientRect();
                  // console.log(chart.xAxis[0], 'chart.xAxis[0]')
                  const plotBandY = y - chartY,
                    plotBandY2 = y - chartY + height,
                    y1Val = chart.yAxis[0].toValue(plotBandY),
                    y2Val = chart.yAxis[0].toValue(plotBandY2);
                  // console.log(x1Date, 'x1Date')
                  // console.log(x2Date, 'x2Date')

                  // console.log(plotBandAnchor.current, draggingId.current, 'plotBandAnchor.current')
                  if(['move', 'resize-from-top'].includes(plotBandAnchor.current)){
                    dispatch(updatePlotHighlights({
                      id: draggingId.current, path: 'fromY', newValue: y1Val < 1 ? 0 : y1Val
                    }))
                  }
                  if(['move', 'resize-from-bottom'].includes(plotBandAnchor.current)){
                    dispatch(updatePlotHighlights({
                      id: draggingId.current, path: 'toY', newValue: y2Val < 1 ? 0 : y2Val
                    }))
                  }
                }

                // alert("From: " + x1Date + ', To: ' + x2Date)
                console.log(dragging.current, 'dragging.current')
                dragging.current = null;
                draggingAxis.current = null
                plotBandAnchor.current = 'move'
                clickX.current = null;
                clickY.current = null;
                draggingId.current = null;
                translateX.current = 0;
                translateY.current = 0;
              }
            }
          },
          click: function (event) {
            // const label = this.renderer.label(
            //   'x: ' + numberFormat(event.xAxis[0].value, 2) +
            //   ', y: ' + numberFormat(event.yAxis[0].value, 2),
            //   event.xAxis[0].axis.toPixels(event.xAxis[0].value),
            //   event.yAxis[0].axis.toPixels(event.yAxis[0].value)
            // )
            //   .attr({
            //     fill: getOptions().colors[0],
            //     padding: 10,
            //     r: 5,
            //     zIndex: 8
            //   })
            //   .css({
            //     color: '#FFFFFF'
            //   })
            //   .add();
            // this.renderer.text('Hello world', 200, 100).attr({ rotation: 45. }).css({ fontSize: '16pt', color: 'green' }).add();
            //
            // setTimeout(function () {
            //   label.fadeOut();
            // }, 1000);
            onAddAnnotation(event)
          }
        },
        type: chartType,
        zoomType: 'x',
        scrollablePlotArea: chartMinWidth,
      },
      xAxis: {
        ...defaultConfigs.xAxis,
        categories: categories,

        // crosshair: true,
        // type: "datetime",
        // labels: {
        //   formatter: function () {
        //     return Highcharts.dateFormat('%b/%e/%Y', this.value);
        //   }
        // }
        plotBands: [...xPlotBands],
        plotLines: [...xPlotLines]
      },
      yAxis: {
        ...defaultConfigs.yAxis,
        backgroundColor: 'transparent',
        min: 0,
        plotBands: [...yPlotBands],
        plotLines: [...yPlotLines],
      },
      annotations: [{
        labels: annotations
      }],
      stockTools: {
        gui: {
          enabled: false,
          visible: false,
        },
        buttons: []
      },
      plotOptions: {
        ...defaultConfigs.yAxis,
        series: {
          dataLabels: dataLabelsData?.all || {},
          marker: {
            enabled: false
          }
        },
        line: {
          // pointPadding: 0.2,
          // borderWidth: 0
        }
      },
      series: series,
      navigation: {
        events: {
          "showPopup": "function(t){var n=t.annotation,r=(this.chart.annotations||[]).findIndex((function(e){return e===n}));P8.dispatch(Pc({type:n.labels&&n.labels.length>0?\"label\":\"shape\",index:r,annotation:n})),this.chart.indicatorsPopupContainer||(this.chart.indicatorsPopupContainer=document.getElementsByClassName(\"highcharts-popup-indicators\")[0]),\"indicators\"===t.formType?this.chart.indicatorsPopupContainer.style.display=\"block\":\"annotation-toolbar\"===t.formType&&(this.chart.activeButton||(this.chart.currentAnnotation=t.annotation)),this.popup&&(e=this.popup)}",
          "closePopup": "function(){this.chart.currentAnnotation=null}",
          "selectButton": "function(e){var t=e.button.className+\" highcharts-active\";e.button.classList.contains(\"highcharts-active\")||(e.button.className=t,this.chart.activeButton=e.button)}",
          "deselectButton": "function(e){e.button&&e.button.classList.remove(\"highcharts-active\"),this.chart.activeButton=null}"
        },
        "bindingsClassName": "tools-container"
      }
    }
  }, [
    activeChartAnchor,
    annotations,
    categories,
    chartMinWidth,
    chartType,
    dataLabelsData,
    defaultConfigs,
    series,
    xPlotLines,
    yPlotLines,
    xPlotBands,
    yPlotBands,
    dragging,
    draggingId
  ])

  console.log(defaultConfigs, 'defaultConfigs config')
  console.log(chartConfig, 'chart config')
  return (
    <NonMarketHighChart options={chartConfig}/>
  )
}

export default LineChart